// Easings

export const EASE = {
  linear: [0, 0, 1, 1],
  smothInOut: [0.5, 0, 0.5, 1],
  smothInOut2: [0.36, 0, 0, 1],
  expoIn: [0, 0.1, 0.5, 1],
  expoIn2: [0.02, 0.98, 0.72, 1],
  expoOut: [0.5, 0, 0.99, 0.9],
  expoInOut: [0, 0.74, 1, 0.25],
  expoInOut2: [0.76, 0, 0.25, 1],
}
