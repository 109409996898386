// Scroll-to-top Effect upon clicking pagination button
// Ref: https://stackoverflow.com/a/49924496/3556531

const headerOffset = 120

function scrollToTargetAdjusted(elementId, offset = headerOffset) {
  if (!elementId) return null

  const element = document?.getElementById(elementId)
  const bodyTop = document?.body?.getBoundingClientRect()?.top // 0
  const elementTop = element?.getBoundingClientRect()?.top // x
  const elementPosition = elementTop - (bodyTop + offset)

  // console.log(elementTop, bodyTop, elementPosition)

  if (elementPosition) {
    window.scrollTo({
      top: elementPosition,
      behavior: 'smooth',
    })
  }
}

export default scrollToTargetAdjusted
