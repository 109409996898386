import clsx from 'clsx'
import { useRef, useState, useEffect } from 'react'
import { useSpring, useSprings, animated } from '@react-spring/web'
import { useGesture } from 'react-use-gesture'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import clamp from 'lodash.clamp'
import { CustomImage } from '../index'

import Styles from './ImageSlider.module.scss'

// todo: Move this hook outside
// Hook useWindowSize
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}

// ImageSlider
const ImageSlider = ({
  sliderImages,
  controllerHidden = false,
  controllerPositionX = 'left', //left, right,
  controllerPositionY = 'top', //top, bottom,
  clipPath = 'false', //left, right,
  className = 'w-full',
  captionClassName,
  imageAspectRatio,
  imageClassName,
  fromScale = 1,
  gutterPct = 0.05,
  gutter = 20,
  controllerMobBottomCenter = false,
  indexInit = 0,
}) => {
  indexInit = sliderImages.length <= indexInit ? 0 : indexInit
  const index = useRef(indexInit)
  const [active, setActive] = useState(true)
  const offset = 0
  const [containerRef, { width }] = useMeasure({
    scroll: true,
    polyfill: ResizeObserver,
  })
  const [immediate, setImmediate] = useState(true)
  const size = useWindowSize()
  const [drag, setDrag] = useState(true)

  // Animation Init
  const [springs, updateSprings] = useSprings(
    sliderImages.length,
    i => ({
      x: (i - index.current) * (width + (width * gutterPct + gutter)) + offset,
      scale: i === index.current ? 1 : fromScale,
      default: { immediate: immediate },
    }),
    [width, immediate]
  )

  useEffect(() => {
    setTimeout(() => {
      setImmediate(false)
    }, 100)
  }, [immediate])

  // Animation update
  const runSprings = (active, mx, xDir, distance, cancel) => {
    if (active && distance > width / 6) {
      index.current = clamp(index.current + (xDir > 0 ? -1 : 1), 0, sliderImages.length - 1)
      cancel()
    }

    // Counter
    if (index.current + 1 > sliderImages.length) {
      setActive((index.current % sliderImages.length) + 1)
    } else if (index.current < 0) {
      setActive(sliderImages.length + ((index.current + 1) % sliderImages.length))
    } else {
      setActive(index.current + 1)
    }

    updateSprings.start(i => {
      const x =
        (i - index.current) * (width + (width * gutterPct + gutter)) + (active ? mx : 0) + offset
      const scale = i === index.current ? 1 : fromScale
      return { x, scale }
    })
  }

  // Gesture
  const bind = useGesture({
    onDrag: ({ active, movement: [mx], direction: [xDir], distance, cancel }) => {
      runSprings(active, mx, xDir, distance, cancel)
      if (drag) {
        setDrag(false)
      }
    },
  })

  // Counter
  function Counter({ currentIndex, data }) {
    const dots = []

    for (const [index] of data.entries()) {
      dots.push(<Dot key={index} active={currentIndex - 1 === index} i={index + 1} />)
    }

    return (
      <div
        className={clsx(
          'flex align-middle',
          controllerPositionX === 'left' && 'mr-0 md:mr-24',
          controllerPositionX === 'right' && 'mr-0 md:ml-24',
          controllerPositionY !== 'bottom' && 'mt-20 md:mt-0',
          controllerPositionY === 'bottom' && 'mr-16 md:mr-0 mt-20'
        )}>
        <nav
          className={clsx(
            'flex flex-wrap space-x-4',
            controllerPositionY !== 'bottom' && 'md:flex-col md:space-y-4 md:space-x-0',
            controllerMobBottomCenter ? 'justify-center md:justify-start' : 'justify-start'
          )}>
          <span className='sr-only'>Slider Navigation</span>
          {dots}
        </nav>
      </div>
    )
  }

  // Dots
  function Dot({ active, i }) {
    const dotStyle = useSpring({
      borderColor: active ? 'color-primary' : 'transparent',
      config: { mass: 5, tension: 500, friction: 80 },
    })

    return (
      <animated.button
        className={clsx(
          Styles.buttonSlider,
          'flex items-center justify-center',
          'text-center text-white text-sm-A',
          'border border-2 border-primary rounded-full',
          'hover:text-secondary hover:bg-primary cursor-pointer duration-300',
          'focus-visible:outline-black focus:outline-none'
        )}
        style={dotStyle}
        onClick={() => DotOnClick(i)}>
        {String(i).padStart(2, '0')}
      </animated.button>
    )
  }

  //  DotOnClick
  const DotOnClick = next => {
    let xDir = 0 < index.current - (next - 1) ? false : true
    index.current = next - 1
    runSprings(true, 0, xDir, -0, true, false)
  }

  const handlePrevOnClick = () => {
    const newIndex = active - 1 > 0 ? active - 1 : sliderImages?.length
    DotOnClick(newIndex)
  }

  const handleNextOnClick = () => {
    const newIndex = active + 1 > sliderImages?.length ? 1 : active + 1
    DotOnClick(newIndex)
  }

  return (
    <div
      className={clsx(
        'relative flex h-full select-none',
        controllerPositionX === 'left' && 'flex-col-reverse md:flex-row',
        controllerPositionX === 'right' && 'flex-col-reverse md:flex-row-reverse justify-start',
        controllerPositionY === 'bottom' &&
          'flex-col-reverse md:flex-col-reverse justify-end items-end'
      )}>
      {!controllerHidden && (
        <div
          className={clsx(
            'absolute left-0 top-0 w-full h-full',
            controllerPositionX === 'left' && 'transform -translate-x-full z-10',
            controllerPositionX === 'right' && 'transform translate-x-full z-10'
          )}
          style={{
            touchAction: 'none',
            cursor: 'default',
          }}></div>
      )}
      {/* Controller */}
      <div
        className={clsx('z-10', controllerHidden && 'hidden', 'flex')}
        style={{
          touchAction: 'none',
          cursor: 'default',
        }}>
        {/* ← (01) 02 03 →*/}
        <Counter currentIndex={active} data={sliderImages} />
      </div>
      <animated.div
        ref={containerRef}
        id='scrolling-content'
        {...bind()}
        style={{
          touchAction: 'auto',
          cursor: 'grab',
          clipPath:
            clipPath === 'left' && size.width >= 768
              ? 'inset( -100vw -100vw -100vw 0 )'
              : clipPath === 'right' && size.width >= 768
              ? 'inset( -100vw 0 -100vw -100vw )'
              : 'none',
          WebkitClipPath:
            clipPath === 'left' && size.width >= 768
              ? 'inset( -100vw -100vw -100vw 0 )'
              : clipPath === 'right' && size.width >= 768
              ? 'inset( -100vw 0 -100vw -100vw )'
              : 'none',
        }}
        className={clsx(
          'relative',
          controllerPositionY === 'bottom' && 'h-3/4',
          className,
          imageAspectRatio
        )}>
        <div>
          {width !== 0 && springs?.length
            ? springs?.map(({ x, scale }, i) => (
                <animated.div
                  key={i}
                  style={{
                    x,
                    scale,
                    willChange: 'transform',
                    touchAction: 'pan-y',
                  }}
                  className={clsx(Styles.ImageContainer, 'absolute w-full h-full md:h-full')}>
                  <CustomImage
                    alt={sliderImages[i]?.image?.altText}
                    src={sliderImages[i]?.image?.sourceUrl}
                    caption={sliderImages[i]?.image?.caption}
                    captionClassName={captionClassName}
                    className={clsx('h-full pointer-events-none', imageClassName, Styles.image)}
                    aspectRatio={imageAspectRatio}
                  />
                  {drag && (
                    <div
                      className={clsx(
                        Styles.slider_drag,
                        'absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2',
                        'flex items-center justify-center w-100 h-100 text-center text-secondary text-sm-A bg-white bg-opacity-90 rounded-full pointer-events-none'
                      )}>
                      CLICK
                      <br />
                      AND
                      <br />
                      DRAG
                    </div>
                  )}
                </animated.div>
              ))
            : null}
        </div>
      </animated.div>
    </div>
  )
}

export default ImageSlider
