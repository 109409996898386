import clsx from 'clsx'
import { Container, CustomImage, GridContainer, SectionContainer } from '../index'

const ContentTwoImages = ({ imageLeft, imageRight, captionLeft, captionRight }) => {
  return (
    <SectionContainer className='mb-80 md:mb-150'>
      <Container>
        <GridContainer>
          <div
            className={clsx(
              'col-span-full',
              'flex flex-col justify-evenly md:flex-row',
              'space-y-4 md:space-x-4 md:space-y-0',
              '-mx-32 md:-mx-30'
            )}>
            <CustomImage
              src={imageLeft.sourceUrl}
              alt={imageLeft.altText}
              caption={captionLeft}
              captionClassName='md:text-sm-A pr-32 md:pr-16'
              aspectRatio='aspect-w-1 aspect-h-1'
              className='w-full'
              imageClassName='bg-image'
              sizes='(max-width: 768px) 100vw, 50vw'
            />
            <CustomImage
              src={imageRight.sourceUrl}
              alt={imageRight.altText}
              caption={captionRight}
              captionClassName='md:text-sm-A pr-32 md:pr-16'
              aspectRatio='aspect-w-1 aspect-h-1'
              className='w-full'
              imageClassName='bg-image'
              sizes='(max-width: 768px) 100vw, 50vw'
            />
          </div>
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default ContentTwoImages
