import { SectionContainer, GridContainer, Container } from '../../components'

const SampleGrids = ({ className = '', children }) => {
  return (
    <SectionContainer>
      <Container>
        <GridContainer className='mb-10 text-center text-white'>
          <div className='col-span-1 bg-red-900'>1</div>
          <div className='col-span-1 bg-red-900'>2</div>
          <div className='col-span-1 bg-red-900'>3</div>
          <div className='col-span-1 bg-red-900'>4</div>
          <div className='col-span-1 bg-red-900'>5</div>
          <div className='col-span-1 bg-red-900'>6</div>
          <div className='col-span-1 bg-red-900'>7</div>
          <div className='col-span-1 bg-red-900'>8</div>
          <div className='col-span-1 bg-red-900'>9</div>
        </GridContainer>

        <GridContainer className='mb-10 text-center text-white'>
          <div className='col-span-4.5 bg-red-900'>1/2 left</div>
          <div className='col-span-4.5 bg-red-900'>1/2 right</div>
        </GridContainer>

        <GridContainer className='mb-10 text-center text-white'>
          <div className='col-span-3 bg-red-900'>1/3 left</div>
          <div className='col-span-3 bg-red-900'>1/3 center</div>
          <div className='col-span-3 bg-red-900'>1/3 right</div>
        </GridContainer>

        <div className='mb-2 text-center'>HOME - ABOUT US block example:</div>
        <GridContainer className='mb-10 text-center text-white'>
          <div className='col-span-4 bg-red-900'>4 cols for the image</div>
          <div className='col-span-4.5 col-start-4.5 bg-red-900'>
            4.5 for the text + 0.5 empty on the left
          </div>
        </GridContainer>

        <GridContainer className='mb-10 text-center text-white'>
          <div className='col-span-4 bg-red-900'>4/9 left</div>
          <div className='col-span-5 bg-red-900'>5/9 center</div>
        </GridContainer>

        <GridContainer className='mb-10 text-center text-white'>
          <div className='col-span-3 bg-red-900'>1/3</div>
          <div className='col-span-3 col-start-4.5 bg-red-900'>1/3</div>
        </GridContainer>

        <GridContainer className='mb-10 text-center text-white'>
          <div className='col-span-4.5 col-start-4.5 bg-red-900'>
            1/2 right, with empty space on the left
          </div>
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default SampleGrids
