import clsx from 'clsx'
import { Link } from '../index'

// Determine the outer wrapper (anchor or div) based on href
const LinkWrapper = ({ href, target, className = '', children }) => {
  return href ? (
    <Link href={href} passHref={true}>
      <a
        // href={href}
        className={clsx('block cursor-pointer', className)}
        target={target}
        rel={target === '_blank' ? 'noopener noreferrer' : ''}>
        {children}
      </a>
    </Link>
  ) : (
    <div className={clsx('block', className)}>{children}</div>
  )
}

export default LinkWrapper
