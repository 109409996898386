import clsx from 'clsx'

import {
  Cta,
  Dash,
  Heading,
  ThreeDMap,
  Paragraph,
  Container,
  SubHeading,
  GridContainer,
  SectionContainer,
} from '../index'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const MapText = ({ cta, heading, subHeading, description, subHeadingSecondary, children }) => {
  const hLengthMax = useLengthOfLongestWord(heading)

  return (
    <SectionContainer className='py-50 md:py-150'>
      <Container>
        {/* Headings */}
        <GridContainer className={'relative z-10'}>
          <SubHeading
            text={subHeading}
            className='col-span-4 mb-35 md:col-start-1.5 md:mb-15'
            onScrollAnimation={true}
          />

          <div className='col-span-4 mb-35 md:col-span-full md:col-start-1.5 md:mb-20'>
            <Heading
              semantics='h2'
              text={heading}
              className={clsx(
                'tracking-4 z-10 text-4xl-D uppercase md:text-8xl',
                hLengthMax > 10 ? 'h2-scale-down-A' : hLengthMax > 8 && 'h2-scale-down-B'
              )}
              indent='29'
              onScrollAnimation={true}
            />
          </div>

          {/* Uniques occurance of 2nd subheading */}
          <div className='col-span-4 mb-40 md:col-span-full md:col-start-3.5 md:mb-90'>
            <Heading
              text={subHeadingSecondary}
              semantics='h3'
              className='tracking-4 z-10 text-xl uppercase md:-ml-20 md:text-4xl-A'
              onScrollAnimation={true}
              onScrollAnimationDelay={0.5}
            />
          </div>
        </GridContainer>

        {children}
      </Container>
    </SectionContainer>
  )
}

export default MapText
