import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import SplitText from '../../utils/SplitText/SplitText'
if (typeof window !== 'undefined') gsap.registerPlugin(SplitText)

import {
  Heading,
  Container,
  Paragraph,
  VideoModal,
  PlayButton,
  SubHeading,
  CustomImage,
  GridContainer,
  SectionHeading,
  SectionContainer,
} from '../index'

import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'
import useIntroEnded from '../../hooks/useIntroEnded'

import s from '../heroProject/HeroProject.module.scss'
import s2 from '../mediaVideo/MediaVideo.module.scss'

const HeroMedia = ({
  heroHeading,
  copyHeading,
  copySubHeading,
  copyPara,
  heroImage,
  heroVideo,
  disableCopy = false,
  headerTitleIndent,
}) => {
  const { introEnded } = useIntroEnded()
  const headingRef = useRef()
  const heading2Ref = useRef()
  const imageRef = useRef()
  const q = gsap.utils.selector(imageRef)
  const qh = gsap.utils.selector(heading2Ref)
  const subHeadingRef = useRef()
  const paragraphRef = useRef()
  const tl = useRef()

  const [isVideoPlayerEnabled, setIsVideoPlayerEnabled] = useState(false)

  useEffect(() => {
    // Split Text
    new SplitText(heading2Ref?.current?.firstChild, {
      type: 'lines',
      linesClass: 'lines font-monumentGrotesk -tracking-3',
    })

    new SplitText(heading2Ref?.current?.firstChild, {
      type: 'lines',
      linesClass: 'overflow-y-hidden -mt-5',
    })

    tl.current = gsap
      .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
      .set(paragraphRef?.current, { opacity: 0 })
      .set(imageRef?.current, { opacity: 0, yPercent: 10 })
      .set(qh('.lines'), { yPercent: 100 })
  }, [])

  useEffect(() => {
    if (introEnded) {
      setTimeout(() => {
        tl.current = gsap
          .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
          .addLabel('init')
          .to(imageRef?.current, { duration: 0.2, opacity: 1 }, 'init')
          .to(imageRef?.current, { yPercent: 0 }, 'init')
          .call(() => headingRef?.current?.animateIn(), null, 'init')
          .call(() => subHeadingRef?.current?.animateIn(), null, 'init+=.25')
          .to(paragraphRef?.current, { duration: 0.3, opacity: 1 }, 'init+=.5')
          .to(qh('.lines'), { yPercent: 0, stagger: 0.15 }, 'init+=.6')
      }, 500)
    }
  }, [introEnded])

  const hLengthMax = useLengthOfLongestWord(heroHeading)

  return (
    <SectionContainer className={clsx('hero-pt md:mb-0', disableCopy ? 'mb-0' : 'mb-80')}>
      <Container>
        {!heroHeading ? null : (
          <GridContainer className='-md:-mx-70'>
            <SectionHeading
              position='center'
              className={clsx('col-span-4 md:col-span-8 md:col-start-1', s.sectionHeading)}>
              <Heading
                semantics='h1'
                text={heroHeading}
                className={clsx(
                  'tracking-4 -ml-16 -mr-16 text-center text-white text-6xl uppercase md:ml-0 md:mr-0 md:text-left md:text-9xl',
                  disableCopy ? 'mb-0' : 'mb-100',
                  hLengthMax > 10 ? 'h1-scale-down-A' : hLengthMax > 8 && 'h1-scale-down-B'
                )}
                indent={headerTitleIndent}
                buildUpAnimation={true}
                ref={headingRef}
              />
            </SectionHeading>
          </GridContainer>
        )}

        {disableCopy ? null : (
          <>
            <GridContainer className='items-start mb-0'>
              {/* COPY (left) + MEDIA (Right) */}

              <div className='col-start-0 col-span-4 md:col-span-3 md:col-start-1'>
                <SubHeading
                  text={copySubHeading}
                  className='mb-40 md:mb-80 md:pt-35'
                  buildUpAnimation={true}
                  ref={subHeadingRef}
                />

                <div ref={paragraphRef}>
                  <div ref={heading2Ref}>
                    <Heading
                      semantics='h2'
                      text={copyHeading}
                      className={clsx(
                        'tracking-4 font-bebasNeue mb-40 text-3xl-A md:mb-50 md:text-4xl-C'
                      )}
                    />
                  </div>

                  <Paragraph text={copyPara} overrideClassName='text-base-A  md:pb-80' />
                </div>
              </div>

              <div
                className={clsx(
                  'relative col-span-4 overflow-hidden md:col-start-5',
                  '-mx-16 mb-80 md:-mr-50 md:mb-0 md:mx-0',
                  'row-start-1 md:row-start-auto',
                  'flex items-center justify-center cursor-pointer',
                  s2.play_btn
                )}
                ref={imageRef}
                onClick={() => setIsVideoPlayerEnabled(true)}>
                <CustomImage
                  src={heroImage?.sourceUrl}
                  alt={heroImage?.altText}
                  className='w-full h-full bg-image object-cover object-center'
                  aspectRatio='aspect-w-4 aspect-h-5 md:aspect-w-7 md:aspect-h-9'
                />

                <PlayButton />
              </div>
            </GridContainer>

            <VideoModal
              id='heroMediaVideo'
              isModalActive={isVideoPlayerEnabled}
              onClose={() => setIsVideoPlayerEnabled(false)}
              src={heroVideo}
            />
          </>
        )}
      </Container>
    </SectionContainer>
  )
}

export default HeroMedia
