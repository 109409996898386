import clsx from 'clsx'
import { forwardRef } from 'react'

const Dash = forwardRef(({ variation = 'long', className = '' }, ref) => {
  const longVariation = variation === 'long'
  return (
    <div
      className={clsx(
        'flex-shrink-0 border-b border-current',
        variation === 'long' && 'w-64 mr-8 pt-0',
        variation === 'medium' && 'w-40 mr-10 pt-0',
        variation === 'short' && 'w-20 mr-10 pt-0',
        className
      )}
      ref={ref}></div>
  )
})

export default Dash
