import { useGlobalStore } from '../store'

import bindActions from '../store/bindActions'
import paginationIndexReducer from '../store/reducers/paginationIndex'

// Custom Hook to expose all the props and binded actions
const usePaginationIndex = () => {
  const { state, dispatch } = useGlobalStore()

  // List of Props
  const resortPI = state?.paginationIndex?.resortPI
  const residentialPI = state?.paginationIndex?.residentialPI
  const communityPI = state?.paginationIndex?.communityPI
  const newsPI = state?.paginationIndex?.newsPI
  const magazinesPI = state?.paginationIndex?.magazinesPI

  // List of Actions
  const setResortIndex = paginationIndexReducer?.actions?.setResortIndex
  const increaseResortIndex = paginationIndexReducer?.actions?.increaseResortIndex
  const decreaseResortIndex = paginationIndexReducer?.actions?.decreaseResortIndex
  const resetResortIndex = paginationIndexReducer?.actions?.resetResortIndex
  const setResidentialIndex = paginationIndexReducer?.actions?.setResidentialIndex
  const increaseResidentialIndex = paginationIndexReducer?.actions?.increaseResidentialIndex
  const decreaseResidentialIndex = paginationIndexReducer?.actions?.decreaseResidentialIndex
  const resetResidentialIndex = paginationIndexReducer?.actions?.resetResidentialIndex
  const setCommunityIndex = paginationIndexReducer?.actions?.setCommunityIndex
  const increaseCommunityIndex = paginationIndexReducer?.actions?.increaseCommunityIndex
  const decreaseCommunityIndex = paginationIndexReducer?.actions?.decreaseCommunityIndex
  const resetCommunityIndex = paginationIndexReducer?.actions?.resetCommunityIndex
  const setNewsIndex = paginationIndexReducer?.actions?.setNewsIndex
  const increaseNewsIndex = paginationIndexReducer?.actions?.increaseNewsIndex
  const decreaseNewsIndex = paginationIndexReducer?.actions?.decreaseNewsIndex
  const resetNewsIndex = paginationIndexReducer?.actions?.resetNewsIndex

  // Bind all Actions to globalDispatch (important)
  const boundActions = bindActions(
    {
      setResortIndex,
      increaseResortIndex,
      decreaseResortIndex,
      resetResortIndex,
      setResidentialIndex,
      increaseResidentialIndex,
      decreaseResidentialIndex,
      resetResidentialIndex,
      setCommunityIndex,
      increaseCommunityIndex,
      decreaseCommunityIndex,
      resetCommunityIndex,
      setNewsIndex,
      increaseNewsIndex,
      decreaseNewsIndex,
      resetNewsIndex,
    },
    dispatch
  )

  // expose
  return {
    resortPI,
    residentialPI,
    communityPI,
    newsPI,
    magazinesPI,
    ...boundActions,
  }
}

export default usePaginationIndex
