import clsx from 'clsx'
import { useTransition, animated } from '@react-spring/web'

import { GridItem } from '../../index'

import Styles from './ProjectGridsWithTransition.module.scss'
import StylesGridItem from '../gridItem/GridItem.module.scss'

import MenuClose from '../../../../public/icons/menu-close.svg'

const ProjectGridsWithTransition = ({
  visibleProjects,
  sliceStartIndex,
  isViewportMobile,
  outAnimation,
  testimonials = false,
  isSmallTestimonial = false,
  visibleNth = [],
}) => {
  const transitions = useTransition(visibleProjects, {
    from: { transform: 'translate3d(0%,0,0)', scale: 'scale(1.2)' },
    enter: { transform: 'translate3d(100%,0,0)', scale: 'scale(1)' },
    config: { mass: 1, tension: 120, friction: 26 },
    trail: 100,
  })

  const closePopup = () => {
    document.querySelectorAll('#projects-grid .ProjectGridsWith').forEach(item => item.classList.remove('opacity-10'))
    document.querySelectorAll('.FullTestimonial').forEach(item => item.style.display = 'none')
  }

  return transitions((style, item, transitionObj, index) => (
    <animated.div
      className={clsx(
        'w-full h-full relative ProjectGridsWith',
        !isViewportMobile && index === 1 && isSmallTestimonial === false && 'overflow-hidden row-span-2', // 2nd tall item
        !testimonials && !isViewportMobile && index === 4 && 'row-start-1',
        isSmallTestimonial ? 'md:row-start-3' : '',
      )}>
      { item && 
        <>
          { 
            item?.testimonial && 
            <div className={clsx(Styles.fullTestimonial, 'FullTestimonial', isSmallTestimonial ? Styles.isSmallTestimonial : '')} index={index}>              
              <q className={clsx(StylesGridItem.quote)}>{item?.testimonial?.quote}</q>
              <p className={StylesGridItem.name}>{item?.testimonial?.name}</p>

              <button 
                onClick={() => closePopup()}
                className={clsx(
                  'text-xs-A md:text-sm-A uppercase cursor-pointer absolute',
                  'top-15 left-15 z-10 hover:underline focus-visible:outline-black focus:outline-none'
                )}
              >Close</button>

              <button
                onClick={() => closePopup()}
                aria-label='Close'
                className={clsx(
                  'absolute z-50 bottom-30 md:bottom-20 border border-primary-light',
                  'flex items-center justify-center',
                  'rounded-full focus-visible:outline-black focus:outline-none cursor-pointer',
                  'w-32 h-32 md:w-38 md:h-38',
                  'text-white',
                  'h-contrast'
                )}>
                <MenuClose className='w-14 h-14 fill-current md:w-14 md:h-14' />
              </button>
            </div>
          }
          <GridItem
            style={style}
            key={index}
            item={item}
            index={sliceStartIndex + ++index} // displayIndex
            tall={!isViewportMobile && index === 2} // 2nd tall item
            isViewportMobile={isViewportMobile}
            outAnimation={outAnimation}
            visibleNth={visibleNth}
            isSmallTestimonial={isSmallTestimonial}
          />
        </>
      }
    </animated.div>
  ))
}

export default ProjectGridsWithTransition
