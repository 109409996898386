// Styles
import Styles from './SkipToMainContent.module.scss'

// Component
const SkipToMainContent = () => {
  /**
   * DOM:
   */
  return (
    <a className={Styles.skipToContentLink} href='#main-content'>
      Skip to main content
    </a>
  )
}

// Export Pure Component
export default SkipToMainContent
