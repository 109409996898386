import { Container, CustomImage, GridContainer, SectionContainer } from '../index'

const NewsImage = ({ image }) => {
  return (
    <SectionContainer className='mb-80 md:mb-150'>
      <Container>
        <GridContainer>
          <div className='col-span-full'>
            <CustomImage
              src={image.sourceUrl}
              alt={image.altText}
              objectProp='object-cover'
              aspectRatio='aspect-w-16 aspect-h-9'
              className={'-mx-32 md:-mx-30'}
              captionClassName='pr-16 md:pr-0'
            />
          </div>
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default NewsImage
