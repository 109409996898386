// ------------- Pagination Indexs (PIs) -------------

// #1 Initial state
const initialState = {
  resortPI: 1,
  residentialPI: 1,
  communityPI: 1,
  newsPI: 1,
  // magazinesPI: 1, // ??
}

// #2 Possible fixed Actions on the state
const setResortIndex = value => ({ type: 'SET_RESORT', payload: value }) // Resort
const resetResortIndex = () => ({ type: 'RESET_RESORT' })
const setResidentialIndex = value => ({ type: 'SET_RESIDENTIAL', payload: value }) // Residential
const resetResidentialIndex = () => ({ type: 'RESET_RESIDENTIAL' })
const setCommunityIndex = value => ({ type: 'SET_COMMUNITY', payload: value }) // Community
const resetCommunityIndex = () => ({ type: 'RESET_COMMUNITY' })
const setNewsIndex = value => ({ type: 'SET_NEWS', payload: value }) // News
const resetNewsIndex = () => ({ type: 'RESET_NEWS' })

// #3 Dispatch above actions with the help of reducers
function paginationIndexReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_RESORT':
      return { ...state, resortPI: action?.payload }
    case 'RESET_RESORT':
      return { ...state, resortPI: 1 }
    case 'SET_RESIDENTIAL':
      return { ...state, residentialPI: action?.payload }
    case 'RESET_RESIDENTIAL':
      return { ...state, residentialPI: 1 }
    case 'SET_COMMUNITY':
      return { ...state, communityPI: action?.payload }
    case 'RESET_COMMUNITY':
      return { ...state, communityPI: 1 }
    case 'SET_NEWS':
      return { ...state, newsPI: action?.payload }
    case 'RESET_NEWS':
      return { ...state, newsPI: 1 }

    default:
      return state
  }
}

export default {
  initialState,
  actions: {
    setResortIndex,
    resetResortIndex,
    setResidentialIndex,
    resetResidentialIndex,
    setCommunityIndex,
    resetCommunityIndex,
    setNewsIndex,
    resetNewsIndex,
  },
  reducer: paginationIndexReducer,
}
