import { useState, useEffect } from 'react'

// Returns length of longest word in a string
// eg: "Post Construction" -> 12
// helpful to conditionally apply classes to lower the size of headings in smaller viewports

const useLengthOfLongestWord = (string = '') => {
  const [length, setLength] = useState(0)

  useEffect(() => {
    let prevLength = 0
    string &&
      string
        .trim() // trim spaces before and after text
        .replace('<br />', '') // trim out html line break tag
        .split('\n') // split string based on new lines
        .forEach(line => {
          let purgedLine = line.replace('\\P{L}+', '')
          // console.log(`#Line -> ${purgedLine} - ${purgedLine.length}`)

          // now for each line find the longest word
          purgedLine.split(' ').forEach(word => {
            // regex to clean up non-unicode characters from the word
            // ref: https://stackoverflow.com/a/17061311/3556531
            let purgedWord = word.replace('\\P{L}+', '')

            if (prevLength < purgedWord.length) prevLength = purgedWord.length
            // console.log(`### Words -> ${purgedWord} - ${purgedWord.length}`)
          })
        })

    setLength(prevLength)
  }, [string])

  return length
}

export default useLengthOfLongestWord
