import { useState } from 'react'
import { Input, Button, Paragraph } from '../../index'
import { EMAIL_REGEX, FILE_SIZE_LIMIT_MB } from '../../../utils/siteConfig'

const JobApplicationForm = ({ className = '' }) => {
  const defaultFormFields = {
    name: '',
    email: '',
    phone: '',
    resume: '', // file
    coverLetter: '', // file
    otherFiles: '', // file - optional
  }

  // Only list Required fields below for validation check
  const defaultValidFields = {
    name: false,
    email: false,
    phone: false,
    resume: false, // file
    coverLetter: false, // file
    // otherFiles: true, // file - optional
  }

  const [formFields, setFormFields] = useState(defaultFormFields)
  const [validFields, setValidFields] = useState(defaultValidFields)
  const [submitAttempt, setFormSubmitAttempt] = useState(false)
  const [isFormValid, setFormValid] = useState(false)

  const handleFieldOnChange = event => {
    const { name } = event?.target
    const { value } = event?.target
    const { files } = event?.target

    switch (name) {
      case 'name':
      case 'phone':
        handleInputOnChange(name, value, true)
        break

      case 'email':
        handleEmailInputOnChange(name, value, true)
        break

      case 'resume':
      case 'coverLetter':
        handleFileInputOnChange(name, value, files, true)
        break

      case 'otherFiles':
        handleFileInputOnChange(name, value, files, false)
        break
      default:
        break
    }
  }

  // #1 Save Non-empty values on FormState
  // #2 Update their validity on ValidFields State
  const handleInputOnChange = (name, value, isRequired) => {
    const newFormState = { ...formFields, [name]: value }
    setFormFields(newFormState)

    // Only update validity state of Required field based on input length
    if (isRequired) {
      const isValidField = !!value?.length
      const newValidFields = { ...validFields, [name]: isValidField }
      setValidFields(newValidFields)
      checkFormValidity(newValidFields)
    }
  }

  const handleEmailInputOnChange = (name, value, isRequired) => {
    const newFormState = { ...formFields, [name]: value }
    setFormFields(newFormState)

    // Only update validity state of Required field based on
    // 1. Input length &
    // 2. Formatting
    if (isRequired) {
      const isValidLength = !!value?.length
      const isValidFormat = value?.match(EMAIL_REGEX) !== null
      const isValidEmail = isValidLength && isValidFormat

      const newValidFields = { ...validFields, [name]: isValidEmail }
      setValidFields(newValidFields)
      checkFormValidity(newValidFields)
    }
  }

  const handleFileInputOnChange = (name, value, files, isRequired) => {
    const fileSize = files?.[0].size / 1024 / 1024 // in MB
    const isFileSizeValid = fileSize <= FILE_SIZE_LIMIT_MB

    // Store files on FormState only is fileSize is valid
    const newFormState = {
      ...formFields,
      [name]: isFileSizeValid ? value : '',
    }
    setFormFields(newFormState)

    // Only update validity state of Required File-Fields (exempt - OtherFiles)
    if (isRequired) {
      const newValidFields = { ...validFields, [name]: isFileSizeValid }
      setValidFields(newValidFields)
      checkFormValidity(newValidFields)
    }
  }

  // Check for entire form's validity
  const checkFormValidity = newValidFields => {
    let isFormValid = true // optimistic checks
    for (const [key, value] of Object.entries(newValidFields)) {
      isFormValid = isFormValid && !!value
    }

    setFormValid(isFormValid)
  }

  const handleSubmit = event => {
    event.preventDefault()
    setFormSubmitAttempt(true)

    if (isFormValid) {
      // Make API call here
      alert(`Form Entries are valid!\nData:\n${JSON.stringify(formFields)}`)
      // console.log('*** VALID FORM ENTRIES ***\n', formFields);
    }
  }

  return (
    <form
      name='contactForm'
      onSubmit={handleSubmit}
      // className='grid grid-cols-2 p-1 bg-gray-300'
      className={`${className} grid grid-cols-1 md:grid-cols-2 md:px-80 md:gap-x-50 gap-y-20 md:gap-y-40`}>
      <Input
        label='Name'
        name='name'
        type='text'
        value={formFields?.name}
        onChange={handleFieldOnChange}
        invalid={!!(!validFields?.name && submitAttempt)}
      />

      <Input
        label='Email'
        name='email'
        type='text'
        value={formFields?.email}
        onChange={handleFieldOnChange}
        invalid={!!(!validFields?.email && submitAttempt)}
      />

      <Input
        label='Phone'
        name='phone'
        type='number'
        value={formFields?.phone}
        onChange={handleFieldOnChange}
        invalid={!!(!validFields?.phone && submitAttempt)}
      />

      <Input
        label='Resume'
        name='resume'
        type='file'
        value={formFields?.resume}
        onChange={handleFieldOnChange}
        onClick={event => (event.target.value = null)} // incase user wants to upload another file
        accept='.pdf,.doc,.docx,application/msword'
        invalid={!!(!validFields?.resume && submitAttempt)}
      />

      <Input
        label='Cover Letter'
        name='coverLetter'
        type='file'
        value={formFields?.coverLetter}
        onChange={handleFieldOnChange}
        onClick={event => (event.target.value = null)} // incase user wants to upload another file
        accept='.pdf,.doc,.docx,application/msword'
        invalid={!!(!validFields?.coverLetter && submitAttempt)}
      />

      <Input
        label='Other Files'
        name='otherFiles'
        type='file'
        value={formFields?.otherFiles}
        defaultValue={defaultFormFields?.otherFiles}
        onChange={handleFieldOnChange}
        onClick={event => (event.target.value = null)} // incase user wants to upload another file
        accept='.pdf,.doc,.docx,application/msword'
        // invalid={!validFields?.otherFiles && submitAttempt }
      />

      <div>
        <Button text='Send Application' />

        {/* Generic Error Message */}
        {submitAttempt && !isFormValid ? (
          <Paragraph
            text='One or more fields have an error.</br>Attached files must not exceed 8MB.</br>Please check and try again.'
            overrideClassName='mt-20 text-red-500 text-xs-A md:mt-40 md:text-base'
          />
        ) : null}
      </div>
    </form>
  )
}

export default JobApplicationForm
