import clsx from 'clsx'
import Arrow from '../../../../public/icons/arrow-down-no-border.svg'
import Styles from './ArrowButton.module.scss'

const ArrowButton = ({
  buttonClickHandler,
  className = '',
  arrowDirection = 'up',
  hoverFlip = false,
  hidden,
  ariaLabel = '',
  disabled = false,
}) => {
  if (hidden) return null

  return buttonClickHandler ? (
    <button
      disabled={disabled}
      onClick={buttonClickHandler}
      aria-label={ariaLabel}
      className={clsx(
        Styles.arrow_button,
        'flex items-center justify-center rounded-full',
        'focus-visible:outline-black focus:outline-none',
        'w-40 h-40 md:w-50 md:h-50',
        !hoverFlip && 'hover:text-primary hover:bg-secondary text-secondary bg-primary', // default
        hoverFlip && 'hover:text-secondary hover:bg-primary text-primary bg-secondary',
        disabled ? 'opacity-0 pointer-events-none' : 'pointer',
        className
      )}>
      <Arrow
        className={clsx(
          'w-1/4 h-auto fill-current object-contain',
          arrowDirection === 'up' && 'transform rotate-0',
          arrowDirection === 'right' && 'transform rotate-90',
          arrowDirection === 'down' && 'transform rotate-180',
          arrowDirection === 'left' && 'transform -rotate-90'
        )}
      />
    </button>
  ) : (
    <div
      className={clsx(
        Styles.arrow_button,
        'flex items-center justify-center rounded-full',
        'focus-visible:outline-black focus:outline-none',
        'w-40 h-40',
        !hoverFlip && 'text-secondary bg-primary', // default
        hoverFlip && 'text-primary bg-secondary',
        disabled ? 'opacity-0 pointer-events-none' : 'pointer',
        className
      )}>
      <Arrow
        className={clsx(
          'w-1/4 h-auto fill-current object-contain',
          arrowDirection === 'up' && 'transform rotate-0',
          arrowDirection === 'right' && 'transform rotate-90',
          arrowDirection === 'down' && 'transform rotate-180',
          arrowDirection === 'left' && 'transform -rotate-90'
        )}
      />
    </div>
  )
}

export default ArrowButton
