// Test comment
// Default Format -> MON DD, YYYY
const formatDate = date => {
  const dateString = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  })

  // console.log('DateString', dateString);
  return dateString
}

export default formatDate
