import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import clsx from 'clsx'

import { useHeadingAnimationRaceConditionFix } from '@big-d-companies/animation'

import { Heading } from '../index'
import useIntroEnded from '../../hooks/useIntroEnded'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const HeroText = ({ text, className, indent }) => {
  const headingRef = useRef()
  const tl = useRef()

  const { introEnded } = useIntroEnded()
  const animateHeadingIn = useHeadingAnimationRaceConditionFix(headingRef)

  useEffect(() => {
    if (introEnded) {
      tl.current = gsap
        .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
        .addLabel('init')
        .call(animateHeadingIn, null, 'init')
    }
  }, [introEnded])

  const hLengthMax = useLengthOfLongestWord(text)

  return (
    <Heading
      semantics='h1'
      text={text}
      className={clsx(
        className,
        hLengthMax > 10 ? 'h1-scale-down-A' : hLengthMax > 8 && 'h1-scale-down-B'
      )}
      indent={indent}
      buildUpAnimation={true}
      ref={headingRef}
    />
  )
}

export default HeroText
