import { useEffect, useCallback, useRef } from 'react'

import { useResize } from './useResize2'

import { BREAKPOINTS } from '../utils/siteConfig'

export const useWindowSizeCache = (callback, dependencies) => {
  const func = useCallback(callback, dependencies)
  const isMobile = useRef(null)

  useEffect(() => {
    const isDevice = () => {
      let hasTouchScreen = false
      if ('maxTouchPoints' in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0
      } else if ('msMaxTouchPoints' in navigator) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0
      } else {
        const mQ = window.matchMedia && matchMedia('(pointer:coarse)')
        if (mQ && mQ.media === '(pointer:coarse)') {
          hasTouchScreen = !!mQ.matches
        } else if ('orientation' in window) {
          hasTouchScreen = true // deprecated, but good fallback
        } else {
          // Only as a last resort, fall back to user agent sniffing
          const UA = navigator.userAgent
          hasTouchScreen =
            /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
            /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
        }
      }
      return hasTouchScreen
    }

    isMobile.current = isDevice() && window.innerWidth <= BREAKPOINTS.M
  }, [])

  const isInitial = useRef(true)
  const windowSize = useRef({ width: 0, height: 0 })

  useEffect(() => {
    if (!isInitial.current) return
    isInitial.current = false

    windowSize.current.width = window.innerWidth
    windowSize.current.height = window.innerHeight

    func(windowSize.current)
  }, [func])

  useResize(
    ({ isLastTick }) => {
      const isAllowed = isLastTick && !isInitial.current
      const isCausedByBrowserBar =
        isMobile.current && windowSize.current.width === window.innerWidth

      if (!isAllowed || isCausedByBrowserBar) return

      windowSize.current.width = window.innerWidth
      windowSize.current.height = window.innerHeight

      func(windowSize.current)
    },
    [func]
  )
}
