import clsx from 'clsx'
import { useEffect, useState } from 'react'

import Styles from './MediaGridImages.module.scss'

import {
  Heading,
  Paragraph,
  Container,
  Pagination,
  SubHeading,
  ArrowButton,
  GridContainer,
  SectionContainer,
  ProjectGridsWithTransition,
} from '../index'
import { useWindowDimensions } from '../../hooks'
import usePaginationIndex from '../../hooks/usePaginationIndex'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const MediaGridImages = ({ heading, subHeading, description, projects, category, signatureProjects, testimonials = false, isSmallTestimonial = false }) => {
  const { width } = useWindowDimensions() // hook to get dynamic height/width

  const [pageSize, setPageSize] = useState(width <= 768 ? (isSmallTestimonial ? 4 : 6) : (isSmallTestimonial ? 4 : (testimonials ? 7 : 6))) // Current Page Index
  const [isViewportMobile, setViewPortMobile] = useState(width <= 768) // Current Page Index
  const [pageCount, setPageCount] = useState(0)

  const { resortPI, residentialPI, setResortIndex, setResidentialIndex } = usePaginationIndex() // GlobalStore
  const [page, setPage] = useState(category === 'resorts' ? resortPI : residentialPI) // Current Page Index

  // sync local state with GlobalStore
  useEffect(
    () => (category === 'resorts' ? setResortIndex(page) : setResidentialIndex(page)),
    [page]
  )

  const [sliceStartIndex, setSliceStartIndex] = useState(1) // Offset in the main array
  const [sliceEndIndex, setSliceEndIndex] = useState(1) // Offset in the main array
  const [visibleProjects, setVisibleProjects] = useState([]) // active projects
  const [outAnimation, setOutAnimation] = useState(false) // active projects
  const [allProjects, setAllProjects] = useState([])
  const [visibleNth, setVisibleNth] = useState([])

  const everyNth = (arr, nth) => arr.filter((e, i) => i % nth === nth - 1);

  useEffect(() => {
    if (signatureProjects && signatureProjects.projects.length) {
      const _projects = projects.filter(item1 => !signatureProjects.projects.some(item2 => item1 && item2 && item2.uri === item1.uri))
      const size = 6
      const result = []

      for (let i = 0; i < _projects.length; i += size) {
        const chunk = _projects.slice(i, i + size)
        result.push(chunk)
      }

      let signatureProjectsList = []
      for (let index = 0; index < (size - result[result.length - 1].length) + signatureProjects.projects.length; index++) {
        signatureProjectsList.push(signatureProjects.projects[index] ? signatureProjects.projects[index] : null)
      }

      signatureProjectsList = signatureProjectsList.sort(a => a ? 1 : -1)

      setAllProjects([..._projects, ...signatureProjectsList])
    } else {
      setAllProjects(projects)
    }    
  }, [projects, signatureProjects])

  useEffect(() => {
    if (allProjects.length) {
      setPageCount(Math.ceil(allProjects?.length / pageSize))

      const sliceStartIndex = (page - 1) * pageSize // pagination start index
      const sliceEndIndex = sliceStartIndex + pageSize // pagination end index

      setSliceStartIndex(sliceStartIndex)
      setSliceEndIndex(sliceEndIndex)
      
      setVisibleProjects(allProjects?.map(i => i).slice(sliceStartIndex, sliceEndIndex))
      setTimeout(() => setOutAnimation(false), 500)
    }
  }, [page, outAnimation, allProjects])

  useEffect(() => {
    if (isSmallTestimonial) {      
      setVisibleNth(Object.keys(allProjects).map(item => Number(item) + 1));
    } else {
      setVisibleNth(
        [
          ...everyNth(Object.keys(allProjects), 5).map(item => Number(item)).map((item, i) => item > 5 ? item + (2 * i) : item).map(item => item + 1),
          ...everyNth(Object.keys(allProjects), 6).map(item => Number(item)).map((item, i) => item > 6 ? item + (1 * i) : item).map(item => item + 1)
        ]
      )
    }
  }, [allProjects]);
    
  const hLengthMax = useLengthOfLongestWord(heading)

  // MAIN RENDER
  return (
    <SectionContainer className={clsx('mb-80 md:mb-160')} id='projects-grid'>
      <Container>
        {/* Heading */}
        <GridContainer className={clsx('mb-40 md:mb-130', testimonials ? Styles.testimonial : '', isSmallTestimonial ? Styles.isSmallTestimonial : '')}>
          <div className={clsx('col-span-4 row-start-1 md:col-span-9 md:row-start-auto md:pb-0', isSmallTestimonial ? 'md:col-start-0' : 'md:col-start-0.5')}>
            <SubHeading text={subHeading} className='mb-40 md:mb-35' onScrollAnimation={true} style={{marginBottom: testimonials ? '-50rem' : '0'}} />

            <Heading
              semantics='h2'
              text={heading}
              className={clsx(
                'md:tracking-4 text-4xl-D uppercase md:text-8xl',
                hLengthMax > 10 ? 'h2-scale-down-A' : hLengthMax > 8 && 'h2-scale-down-B'
              )}
              indent='15'
              onScrollAnimation={true}
            />
          </div>
        </GridContainer>

        { !!allProjects.length && 
          <DesktopView
            description={description}
            projects={allProjects}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            pageCount={pageCount}
            visibleProjects={visibleProjects}
            sliceStartIndex={sliceStartIndex}
            sliceEndIndex={sliceEndIndex}
            isViewportMobile={isViewportMobile}
            outAnimation={outAnimation}
            setOutAnimation={setOutAnimation}
            testimonials={testimonials}
            isSmallTestimonial={isSmallTestimonial}
            signatureProjects={signatureProjects}
            visibleNth={visibleNth}
          />
        }

        { !!allProjects.length && 
          <MobileView
            description={description}
            projects={allProjects}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            pageCount={pageCount}
            visibleProjects={visibleProjects}
            sliceStartIndex={sliceStartIndex}
            sliceEndIndex={sliceEndIndex}
            isViewportMobile={isViewportMobile}
            setOutAnimation={setOutAnimation}
            signatureProjects={signatureProjects}
            isSmallTestimonial={isSmallTestimonial}
            visibleNth={visibleNth}
          />
        }
      </Container>
    </SectionContainer>
  )
}

export default MediaGridImages

// Slider uses two separate layout for Desktop & Mobile views
const DesktopView = ({
  description,
  projects,
  page,
  pageSize,
  setPage,
  pageCount,
  visibleProjects,
  sliceStartIndex,
  sliceEndIndex,
  isViewportMobile,
  outAnimation,
  setOutAnimation,
  signatureProjects,
  testimonials = false,
  isSmallTestimonial = false,
  visibleNth,  
}) => (
  <GridContainer className='hidden md:block'>
    <div className='col-span-full'>
      <div className={clsx('grid gap-24 grid-cols-4', isSmallTestimonial ? 'row-start-1 grid-flow-row grid-rows-2' : 'grid-flow-col grid-rows-3')}>
        { pageCount == page && signatureProjects && signatureProjects.visble !== false ?
          <div className='md:col-span-1 md:ml-130 md:mr-150'>
            <h2 className='md:tracking-4 text-3xl-A md:text-4xl-A'>{signatureProjects?.title}</h2>
            <Paragraph text={signatureProjects?.description} />  
          </div> 
        :
          <Paragraph text={description} className={clsx('md:ml-130 md:mr-150', isSmallTestimonial ? 'md:col-span-2' : 'md:col-span-1')} /> 
        }
        
        <div className={clsx('col-start-0 flex row-span-1 row-start-5 items-end md:row-start-2', isSmallTestimonial ? 'md:col-span-1 test123' : '')}>
          <Pagination
            totalItems={projects?.length}
            currentPage={page}
            pageSize={pageSize}
            setCurrentPage={setPage}
            sliceStartIndex={sliceStartIndex}
            sliceEndIndex={sliceEndIndex}
          />
        </div>        

        {/* All Slider Images are rendered inside this */}
        <ProjectGridsWithTransition
          visibleProjects={visibleProjects}
          sliceStartIndex={sliceStartIndex}
          isViewportMobile={isViewportMobile}
          isSmallTestimonial={isSmallTestimonial}
          outAnimation={outAnimation}
          testimonials={testimonials}
          visibleNth={visibleNth}
        />
        {/* <TransitionArray items={visibleProjects} /> */}

        <GridArrowButtons
          className={clsx(
            'col-end-5 row-start-3 w-full h-full',
            'flex items-center',
            isSmallTestimonial ? 'md:col-span-1 md:row-start-2 justify-end' : 'justify-center',
          )}
          page={page}
          projects={projects}
          pageSize={pageSize}
          setPage={setPage}
          outAnimation={outAnimation}
          setOutAnimation={setOutAnimation}
          />        
      </div>
    </div>
  </GridContainer>
)

const MobileView = ({
  description,
  projects,
  page,
  pageSize,
  setPage,
  pageCount,
  visibleProjects,
  sliceStartIndex,
  sliceEndIndex,
  isViewportMobile,
  setOutAnimation,
  signatureProjects,
  isSmallTestimonial = false
}) => (
  <GridContainer className='block md:hidden'>
    <div className='col-span-full'>
      {/* <Paragraph text={description} className='' /> */}

      { pageCount == page && signatureProjects &&  signatureProjects.visble !== false ?
          <div>
            <h2 className='md:tracking-4 text-3xl-A md:text-4xl-A'>{signatureProjects?.title}</h2>
            <Paragraph text={signatureProjects?.description} className='mb-60' />  
          </div> 
        :
          <Paragraph text={description} className={clsx(isSmallTestimonial ? 'mb-20' : 'mb-60')} />
        }

      <div className={clsx('grid gap-16 grid-flow-row grid-cols-2', isSmallTestimonial ? 'grid-rows-2 md:grid-rows-3' : 'grid-rows-3')}>
        {/* All Slider Images are rendered inside this */}
        <ProjectGridsWithTransition
          visibleProjects={visibleProjects}
          sliceStartIndex={sliceStartIndex}
          isViewportMobile={isViewportMobile}
          isSmallTestimonial={isSmallTestimonial}
        />
      </div>

      <Pagination
        enablePrevNext={true}
        className={clsx('flex items-center justify-between mt-12')}
        totalItems={projects?.length}
        currentPage={page}
        pageSize={pageSize}
        setCurrentPage={setPage}
        sliceStartIndex={sliceStartIndex}
        sliceEndIndex={sliceEndIndex}
      />
    </div>
  </GridContainer>
)

const GridArrowButtons = ({
  className,
  page,
  projects,
  pageSize,
  setPage,
  outAnimation,
  setOutAnimation,
}) => {
  const isLastPage = page === Math.ceil(projects?.length / pageSize)
  const disabled = projects?.length <= pageSize // no need to show or render the next arrow button

  return !disabled ? (
    <div className={className}>
      <ArrowButton
        hidden={page === 1}
        buttonClickHandler={() => {
          setTimeout(() => setPage(page - 1), 500)
          setOutAnimation(true)
        }}
        arrowDirection='left'
        className='mr-10 w-60 h-60 md:mr-20 md:w-120 md:h-120'
        ariaLabel='Previous Items'
        outAnimation={outAnimation}
      />

      <ArrowButton
        hidden={isLastPage}
        buttonClickHandler={() => {
          setOutAnimation(true)
          setTimeout(() => setPage(page + 1), 500)
        }}
        arrowDirection='right'
        className='w-60 h-60 md:w-120 md:h-120'
        ariaLabel='Next Items'
      />
    </div>
  ) : null
}
