import clsx from 'clsx'
import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import {
  Cta,
  Dash,
  Heading,
  Paragraph,
  Container,
  SubHeading,
  CustomImage,
  GridContainer,
  SectionContainer,
} from '../index'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const MediaGridText = ({ imageA, imageB, heading, subHeading, description, cta }) => {
  const gridRef = useRef()
  const q = gsap.utils.selector(gridRef)
  const tl = useRef()

  useEffect(() => {
    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 768px)': function () {
        tl.current = gsap
          .timeline({
            scrollTrigger: {
              trigger: gridRef?.current,
              scrub: true,
              invalidateOnRefresh: true,
            },
          })
          .fromTo(
            q('.imageB'),
            {
              yPercent: 25,
            },
            {
              yPercent: -75,
            }
          )

        return function () {
          tl?.current?.kill()
          gsap.set(q('.imageB'), { clearProps: true })
        }
      },
    })
  }, [])

  return (
    <SectionContainer>
      <Container className='pb-0'>
        <div ref={gridRef}>
          <GridContainer>
            {/* Left - Pair of 2 images */}
            <GridImages imageA={imageA} imageB={imageB} />

            {/* Right - Copy */}
            <CopyComponent
              heading={heading}
              subHeading={subHeading}
              description={description}
              cta={cta}
            />
          </GridContainer>
        </div>
      </Container>
    </SectionContainer>
  )
}

// export default MediaGridText;
export default React.memo(MediaGridText)

const GridImages = ({ imageA, imageB }) => (
  <div className='col-span-4 -mx-16 md:col-span-3.5 md:-ml-50 md:mr-0'>
    {/* IMAGE A Hides in mobile view */}
    <CustomImage
      className='hidden w-full h-auto bg-image md:block'
      src={imageA.sourceUrl}
      alt={imageA.altText}
      aspectRatio='aspect-w-1 aspect-h-1'
      sizes='(max-width: 768px) 100vw, 50vw'
    />

    <CustomImage
      className={clsx(
        'imageB',
        'w-full h-auto bg-image md:w-520',
        'md:transform md:-translate-y-190 md:translate-x-1/2',
        'lg:w-520 lg:h-440 lg:transform lg:-translate-y-1/2 lg:translate-x-1/2'
      )}
      src={imageB.sourceUrl}
      alt={imageB.altText}
      aspectRatio='aspect-w-13 aspect-h-11'
      sizes='(max-width: 768px) 100vw, 50vw'
    />
  </div>
)

const CopyComponent = ({ heading, subHeading, description, cta }) => {
  const hLengthMax = useLengthOfLongestWord(heading)

  return (
    <div className='col-span-4 row-start-1 pb-42 md:col-span-5 md:col-start-4 md:row-start-auto md:pb-30'>
      <SubHeading text={subHeading} className='pb-40 md:pb-35 md:pt-20' onScrollAnimation={true} />

      <div className='mb-40 md:mb-100'>
        <Heading
          semantics='h2'
          text={heading}
          className={clsx(
            'tracking-4 text-4xl-D uppercase md:text-8xl',
            hLengthMax > 10 ? 'h2-scale-down-A' : hLengthMax > 8 && 'h2-scale-down-B'
          )}
          onScrollAnimation={true}
        />
      </div>

      <div className={clsx('flex items-start', 'mb-10 pr-16 md:ml-240')}>
        <Dash variation='short' className='hidden pt-10 md:block md:mr-85' />

        <div>
          <Paragraph text={description} className='paragraph mb-40 md:mb-80 md:pr-120' />

          <Cta cta={cta} />
        </div>
      </div>
    </div>
  )
}
