import clsx from 'clsx'
import { gsap } from 'gsap'
import { useEffect, useRef, useState } from 'react'

import { useHeadingAnimationRaceConditionFix } from '@big-d-companies/animation'

import {
  Heading,
  Container,
  Paragraph,
  SubHeading,
  ImageSlider,
  GridContainer,
  SectionContainer,
  CustomImage,
  PlayButton,
  VideoModal,
} from '../index'

import useIntroEnded from '../../hooks/useIntroEnded'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'
import s from '../mediaVideo/MediaVideo.module.scss'

const HeroSlider = ({ heroHeading, heading, subHeading, heroVideo, sliderImages, description }) => {
  const headingRef = useRef()
  const heading2Ref = useRef()
  const imagesSliderRef = useRef()
  const subHeadingRef = useRef()
  const paragraphRef = useRef()
  const tl = useRef()

  const { introEnded } = useIntroEnded()

  const [isVideoPlayerEnabled, setIsVideoPlayerEnabled] = useState(false)
  const animateHeadingIn = useHeadingAnimationRaceConditionFix(headingRef)

  useEffect(() => {
    tl.current = gsap
      .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
      .set(paragraphRef?.current, { opacity: 0 })
      .set(imagesSliderRef?.current, { opacity: 0 })
      .set(imagesSliderRef?.current, { yPercent: 10 })
  }, [])

  useEffect(() => {
    if (introEnded) {
      tl.current = gsap
        .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
        .addLabel('init')
        .call(animateHeadingIn, null, 'init')
        .to(imagesSliderRef?.current, { duration: 0.2, opacity: 1 }, 'init')
        .to(imagesSliderRef?.current, { yPercent: 0 }, 'init')
        .call(() => subHeadingRef?.current?.animateIn(), null, 'init+=.4')
        .call(() => heading2Ref?.current?.animateInLines(), null, 'init+=.6')
        .to(paragraphRef?.current, { duration: 0.3, opacity: 1 }, 'init+=.7')
    }
  }, [introEnded])

  const hLengthMax = useLengthOfLongestWord(heroHeading)

  return (
    <SectionContainer className='hero-pt pb-100 md:pb-0'>
      <Container>
        <GridContainer className='pb-100 md:pb-0'>
          <div className='col-span-4 md:col-span-7 md:col-start-3'>
            <Heading
              semantics='h1'
              text={heroHeading}
              className={clsx(
                'tracking-4 text-white text-6xl uppercase md:ml-50 md:text-9xl',
                hLengthMax > 10 ? 'h1-scale-down-A' : hLengthMax > 8 && 'h1-scale-down-B'
              )}
              indent='30'
              buildUpAnimation={true}
              ref={headingRef}
            />
          </div>
        </GridContainer>

        <GridContainer>
          {/* B - Left Image Slide  */}
          <div className='relative z-0 col-span-4 -ml-16 -mr-16 md:-ml-50' ref={imagesSliderRef}>
            { sliderImages.length == 1 &&               
              <>
                { heroVideo ?
                  <div
                    className={clsx(
                      'relative col-span-4 -ml-16 -mr-16 overflow-hidden md:-ml-50 md:mr-0',
                      'flex items-center justify-center cursor-pointer',
                      s.play_btn
                    )}                
                    onClick={() => setIsVideoPlayerEnabled(true)}>
                    <CustomImage
                      src={sliderImages[0]?.image?.sourceUrl}
                      alt={sliderImages[0]?.image?.altText}
                      className='w-full h-full bg-image object-cover object-center'
                      sizes='(max-width: 768px) 100vw, 50vw'
                    />

                    <PlayButton />
                  </div>
                :
                <div
                  className={clsx(
                    'relative col-span-4 -ml-16 -mr-16 overflow-hidden md:-ml-50 md:mr-0',
                    'flex items-center justify-center',
                    s.play_btn
                )}>
                    <CustomImage
                      src={sliderImages[0]?.image?.sourceUrl}
                      alt={sliderImages[0]?.image?.altText}
                      className='w-full h-full bg-image object-cover object-center'
                      sizes='(max-width: 768px) 100vw, 50vw'
                    />
                  </div>
                }
              </>
            }

            { sliderImages.length > 1 && 
              <ImageSlider
                sliderImages={sliderImages}
                imageClassName='overflow-hidden'
                imageAspectRatio='aspect-w-4 aspect-h-5 md:aspect-w-7 md:aspect-h-9'
                controllerPositionX='right'
                controllerPositionY='bottom'
                clipPath='right'
              />
            }
          </div>

          {/* B - Right - Copy */}
          <div className='z-10 col-span-4 pt-80 md:col-start-5 md:pt-200'>
            <SubHeading
              text={subHeading}
              className='mb-40 md:mb-80'
              ref={subHeadingRef}
              buildUpAnimation={true}
            />
            <div className='pb-40 md:pb-50 md:w-550'>
              {/* Don't apply scale down here - reason: text is paragraph */}
              <Heading
                semantics='h2'
                text={heading}
                className='md:tracking-4 text-3xl-A md:text-4xl-A'
                buildUpAnimation={'lines'}
                ref={heading2Ref}
              />
            </div>
            <div ref={paragraphRef}>
              <Paragraph text={description} className='md:w-500' />
            </div>
          </div>
        </GridContainer>

        { heroVideo && <VideoModal
          id='heroCareersVideo'
          isModalActive={isVideoPlayerEnabled}
          onClose={() => setIsVideoPlayerEnabled(false)}
          src={heroVideo}
        /> }
      </Container>
    </SectionContainer>
  )
}

export default HeroSlider
