import clsx from 'clsx'
import Image from 'next/legacy/image'
import { IMG_LAZY_LOAD_THRESHOLD } from '../../utils/siteConfig'
import { useRef, useState, useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import {
  Cta,
  Heading,
  Container,
  SubHeading,
  LinkWrapper,
  GridContainer,
  SectionContainer,
} from '../index'
import { formatDate, isObjectEmpty } from '../../utils'
import Styles from './SectionItems.module.scss'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const SectionItems = ({
  id = '',
  heading,
  subHeading,
  itemsArray,
  defaultNoOfVisibleItems = 4,
  sectionCta,
  emptyArrayMessage = 'List is empty',
  showAll = false,
  applyDateFormatting = false,
  className,
}) => {
  const [showAllActive, setShowAllActive] = useState(showAll)
  const [visibleItems, setVisibleItems] = useState(
    !showAll ? itemsArray?.slice(0, defaultNoOfVisibleItems) : itemsArray
  )

  const showAllAwards = () => {
    setShowAllActive(true)
    setVisibleItems(itemsArray)
  }

  const tl = useRef()
  const itemsContainerRef = useRef()
  const q = gsap.utils.selector(itemsContainerRef)
  useEffect(() => {
    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 768px)': function () {
        gsap.set(q('.item'), {
          yPercent: 100,
        })
        gsap.set(q('.item-link'), {
          pointerEvents: 'none',
        })

        tl.current = gsap
          .timeline({
            defaults: { duration: 1, ease: 'bigd-ease-out' },
            scrollTrigger: {
              trigger: itemsContainerRef?.current,
              start: '0 80%',
            },
          })
          .to(q('.item'), {
            yPercent: 0,
            stagger: 0.15,
            delay: 0.25,
            overwrite: true,
          })
          .to(q('.item-link'), {
            pointerEvents: 'auto',
          })
      },
      // mobile
      '(max-width: 767px)': function () {
        gsap.set(q('.item'), {
          yPercent: 0,
        })
      },
    })
  }, [])

  const hLengthMax = useLengthOfLongestWord(heading)

  return (
    <SectionContainer className={clsx('mt-80 md:mt-150', className)} id={id}>
      <Container className='pb-100 pt-0 md:pb-30'>
        <GridContainer className='border-b border-primary-light'>
          <div className='col-span-4 mb-60 md:col-span-7 md:col-start-0.5 md:mb-130'>
            <SubHeading text={subHeading} className='mb-40 md:mb-35' onScrollAnimation={true} />
            <Heading
              semantics='h2'
              text={heading}
              className={clsx(
                'tracking-4 text-4xl-C uppercase md:text-8xl',
                hLengthMax > 10 ? 'h2-scale-down-A' : hLengthMax > 8 && 'h2-scale-down-B'
              )}
              onScrollAnimation={true}
            />
          </div>
        </GridContainer>

        {/* Map Open Positions or Awards here */}
        <div ref={itemsContainerRef}>
          {visibleItems?.length ? (
            visibleItems?.map((i, index) => {
              return (
                <LinkWrapper
                  href={i?.externalUrl || i?.cta?.url}
                  target={i?.externalUrl ? '_blank' : null}
                  key={index}
                  className={clsx(
                    Styles.item,
                    'item-link',
                    'border-b border-primary-light overflow-hidden'
                  )}>
                  <GridContainer className='item py-30 md:py-50'>
                    {/* Date */}
                    <SubHeading
                      text={applyDateFormatting ? formatDate(i?.date) : i?.date}
                      className={clsx(
                        'col-span-4 mb-12 text-white md:col-span-1.5 md:-mt-40 md:mb-0',
                        Styles.subheading
                      )}
                    />

                    {/* Image(logo) shown only in mobile view */}
                    {i?.image?.sourceUrl && (
                      <div className='block col-span-4 mb-12 md:hidden'>
                        <Image
                          height='30'
                          width='175' // max-width
                          src={i?.image?.sourceUrl}
                          alt={i?.image?.altText}
                          className='object-contain object-left'
                          lazyBoundary={IMG_LAZY_LOAD_THRESHOLD}
                        />
                      </div>
                    )}

                    <div className='col-span-4 mb-16 md:col-span-5 md:col-start-2 md:mb-0'>
                      <Heading
                        semantics='h3'
                        text={i?.heading}
                        className='tracking-4 mb-10 text-left text-2xl-F uppercase md:mb-4 md:text-3xl-A'
                      />

                      <Heading
                        semantics='h4' // or may be even h5
                        text={i?.description}
                        className='tracking-4 text-white text-base-A uppercase md:text-2xl-A'
                      />
                    </div>

                    {/* CTA */}
                    <div className='col-span-4 md:col-span-2 md:justify-self-end md:place-self-center md:mr-18'>
                      {i?.cta ? (
                        <Cta
                          cta={
                            !i?.cta || isObjectEmpty(i?.cta)
                              ? null
                              : { ...i?.cta, type: 'linkWrapped' }
                          }
                        />
                      ) : (
                        i?.image?.sourceUrl && (
                          <div className='hidden md:block'>
                            <Image
                              height='60'
                              width='250' // max-width
                              src={i?.image?.sourceUrl}
                              alt={i?.image?.altText}
                              className='object-contain object-right'
                              lazyBoundary={IMG_LAZY_LOAD_THRESHOLD}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </GridContainer>
                </LinkWrapper>
              )
            })
          ) : (
            <span>{emptyArrayMessage}</span>
          )}
        </div>

        {/* Section CTA */}
        {!showAllActive ? (
          <Cta
            cta={{ ...sectionCta, clickHandler: showAllAwards }}
            className='flex justify-center pt-20 md:items-end md:justify-end md:pt-30'
          />
        ) : null}
      </Container>
    </SectionContainer>
  )
}

export default SectionItems
