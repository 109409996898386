import { useRef, useEffect, useState } from 'react'
import { gsap } from 'gsap'
import clsx from 'clsx'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import { SITENAME } from '../../utils/siteConfig'

import Container from '../layouts/Container'
import { Heading, Paragraph } from '../index'
import LocationMapSVG from './LocationMapSVG'

import { EASE } from '../../utils/animation'

import s from './LocationMap.module.scss'

const LocationMap = ({
  title,
  content,
  image,
  gridLayout = '4/2',
  data,
  unlicensedStates = [],
  whereWeWork = undefined,
  className,
}) => {
  const sectionRef = useRef()
  const q = gsap.utils.selector(sectionRef)
  const tl = useRef()

  const [locations, setLocations] = useState([])
  const [currentGroupColor, setCurrentGroupColor] = useState('')

  useEffect(() => {
    setTimeout(() => {
      const items = [q(`.${s.title}`), q(`.${s.row2}`)]

      tl.current = gsap
        .timeline({
          defaults: { ease: EASE.expoInOut, overwrite: 'auto' },
          scrollTrigger: {
            trigger: sectionRef?.current,
            start: '0% 85%',
            end: '100% 0',
            once: true,
          },
        })
        .addLabel('init')
        .fromTo(
          items,
          {
            y: '8.5rem',
          },
          {
            y: '0rem',
            duration: 1,
            stagger: 0.15,
          },
          'init'
        )
        .fromTo(
          items,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.333,
            stagger: 0.15,
          },
          'init'
        )
        .set(items, {
          clearProps: true,
        })

      return function () {
        tl?.current?.kill()
        gsap.set(items, { clearProps: true })
      }
    }, 100)
  }, [])

  useEffect(() => {
    if (data && data.locationsMap && data.locationsMap.nodes && data.locationsMap.nodes.length) {
      let listLocations = []

      let actived, currentGroup, allGroups, inactive, unlicensed

      data.locationsMap.nodes.forEach((item, locationsMapIndex) => {
        if (item.businessUnits && item.businessUnits.nodes && item.businessUnits.nodes.length) {
          if (item.businessUnits.nodes[0].slug == SITENAME) {
            actived = item.businessUnits.nodes[0].websiteFields.activedColor
            currentGroup = item.businessUnits.nodes[0].websiteFields.currentGroupColor
            allGroups = item.businessUnits.nodes[0].websiteFields.allGroupColor
            inactive = item.businessUnits.nodes[0].websiteFields.inactiveColor
            unlicensed = item.businessUnits.nodes[0].websiteFields.unlicensedColor

            setCurrentGroupColor(currentGroup)
          }
        }
      })

      data.locationsMap.nodes.forEach((item, locationsMapIndex) => {
        if (item.businessUnits && item.businessUnits.nodes && item.businessUnits.nodes.length) {
          const businessUnitsName = item.businessUnits.nodes[0].name

          let indexLocation = -1
          listLocations.forEach((location, i) => {
            if (location.name === businessUnitsName) {
              indexLocation = i
            }
          })

          if (indexLocation == -1) {
            listLocations.push({
              id: item.businessUnits.nodes[0].id,
              name: businessUnitsName,
              slug: item.businessUnits.nodes[0].slug,
              states: [],
              marked_state: '',
              actived_state: '',
              colors: {
                actived: actived,
                all_groups: allGroups,
                current_group: currentGroup,
                inactive: inactive,
                unlicensed: unlicensed,
              },
              current: item.businessUnits.nodes[0].slug == SITENAME,
              units: [],
            })
          }
        }
      })

      data.locationsMap.nodes.forEach((item, locationsMapIndex) => {
        if (item.businessUnits && item.businessUnits.nodes && item.businessUnits.nodes.length) {
          const businessUnitsName = item.businessUnits.nodes[0].name

          let indexLocation = -1
          listLocations.forEach((location, i) => {
            if (location.name === businessUnitsName) {
              indexLocation = i
            }
          })

          let itemLocation = listLocations[indexLocation]

          let state = ''
          let markedState = item.locationsMapFields.states.filter(state => state.markedState)
          if (markedState.length) {
            state = markedState[0].state.toLocaleLowerCase()
            itemLocation.marked_state = state
            itemLocation.actived_state = state
          }

          listLocations[indexLocation] = itemLocation
        }
      })

      data.locationsMap.nodes.forEach((item, locationsMapIndex) => {
        if (item.businessUnits && item.businessUnits.nodes && item.businessUnits.nodes.length) {
          const businessUnitsId = item.businessUnits.nodes[0].id

          listLocations = listLocations.map(location => {
            if (location.id === businessUnitsId) {
              const newStates = item.locationsMapFields.states.map(s => s.state.toLocaleLowerCase())

              location.states = [...location.states, ...newStates]
              location.states = [...new Set(location.states)]

              const newUnits = item.locationsMapFields.states.map(stateItem => {
                return {
                  title: item.title,
                  image: stateItem?.featuredImage?.sourceUrl,
                  link_label: item.locationsMapFields?.callToActionLabel,
                  link: item.locationsMapFields.callToActionLink?.url,
                  city: stateItem.city,
                  state: stateItem.state.toLocaleLowerCase(),
                  position: { x: stateItem.xCoordinate, y: stateItem.yCoordinate },
                  main:
                    location.marked_state == stateItem.state.toLocaleLowerCase() &&
                    stateItem.markedState
                      ? true
                      : false,
                }
              })

              location.units = [...location.units, ...newUnits]
            }

            return location
          })
        }
      })

      listLocations = listLocations.filter(item => (item.slug != 'companies' ? item : false))

      listLocations = listLocations.sort(item => (item.slug == SITENAME ? -1 : 1))

      setLocations(listLocations)
    }
  }, [data])

  const handleClick = location => {
    const newLocations = JSON.parse(JSON.stringify(locations)).map(item => {
      if (location.id == item.id) {
        item.current = !item.current
      }
      return item
    })
    const count = newLocations.filter(item => item.current).length
    if (count) {
      setLocations(newLocations)
    }
  }

  return (
    <section ref={sectionRef} className={s.locationMap + ' ' + className}>
      <Container>
        <div className={s.row1}>
          {/* <Heading text={title} semantics='h2' className={s.title} /> */}
        </div>

        <div className={s.row2}>
          <LocationMapSVG
            locations={locations}
            unlicensedStates={unlicensedStates}
            whereWeWork={whereWeWork}
            content={content}
          />

          {content.text && (
            <Paragraph
              overrideClassName={clsx(s.text, 'md:text- mb-20 text-base -tracking-3 md:mb-36')}
              text={`
                <strong>${content.title}</strong><br>
                <strong>${content.subtitle}</strong><br>
                ${content.text}
              `}
            />
          )}
        </div>
      </Container>
    </section>
  )
}

export default LocationMap
