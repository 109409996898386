import clsx from 'clsx'
import { useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { gsap } from 'gsap'
import { Dash, Paragraph } from '../index'

const SubHeading = forwardRef(
  (
    {
      text,
      className = '',
      classNameDash = '',
      subHeadingclassName = '',
      dash = true,
      variationDash,
      uppercase = true,
      buildUpAnimation = false,
      onScrollAnimation = false,
      onScrollAnimationTrigger = false,
      onScrollAnimationDelay = 0,
    },
    ref
  ) => {
    const dashRef = useRef()
    const paragraphRef = useRef()

    useImperativeHandle(
      ref,
      () => {
        return {
          animateIn() {
            dash &&
              gsap.to(dashRef?.current, {
                scaleX: 1,
                duration: 0.75,
                ease: 'bigd-ease-out',
              })
            gsap.to(paragraphRef?.current, {
              opacity: 1,
              x: 0,
              yPercent: 0,
              duration: 1,
              ease: 'bigd-ease-out',
            })
          },
        }
      },
      []
    )

    useEffect(() => {
      if (buildUpAnimation || onScrollAnimation || onScrollAnimationTrigger) {
        if (dash) {
          gsap.set(dashRef?.current, {
            scaleX: 0,
            transformOrigin: 'top left',
          })
          gsap.set(paragraphRef?.current, { opacity: 0, x: -10 })
        } else {
          gsap.set(paragraphRef?.current, { opacity: 0, yPercent: 105 })
        }
      }

      if (onScrollAnimation || onScrollAnimationTrigger) {
        dash &&
          gsap.to(dashRef?.current, {
            scaleX: 1,
            duration: 0.5,
            delay: onScrollAnimationDelay,
            ease: 'bigd-ease-out',
            scrollTrigger: {
              trigger: onScrollAnimationTrigger
                ? onScrollAnimationTrigger.current
                : dashRef?.current,
              start: 'top 70%',
            },
          })
        gsap.to(paragraphRef?.current, {
          opacity: 1,
          x: 0,
          y: 0,
          duration: 0.75,
          delay: onScrollAnimationDelay,
          scrollTrigger: {
            trigger: onScrollAnimationTrigger ? onScrollAnimationTrigger.current : dashRef?.current,
            start: 'top 70%',
          },
        })
      }
    }, [])

    if (!text) return null
    return (
      <div className={`flex items-center flex-grow-0 ${className}`}>
        {dash ? <Dash variation={variationDash} ref={dashRef} className={classNameDash} /> : null}
        {text !== 'none' ? (
          <Paragraph
            text={text}
            overrideClassName={clsx(
              'text-xs-A md:text-sm-A',
              uppercase && 'uppercase',
              subHeadingclassName
            )}
            ref={paragraphRef}
          />
        ) : null}
      </div>
    )
  }
)

export default SubHeading
