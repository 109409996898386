import clsx from 'clsx'
import { useState, useEffect, useRef } from 'react'
import { useSprings, animated } from '@react-spring/web'
import { useGesture } from '@use-gesture/react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import Image from 'next/legacy/image'
import { Link, Heading, Paragraph } from '../index'

import { BREAKPOINTS } from '../../utils/siteConfig'

import { useWindowSizeCache } from '../../hooks/useWindowSizeCache'

import s from './HeroSlider2.module.scss'

import { PlayButton, VideoModal } from '../../components/index'

const HeroSlider2 = ({
  sliderItems: items,
  className,
  classNameTest,
  innerClassName = '',
  isProjectDetailPage = false,
}) => {
  const [id, SetId] = useState(0)
  const [btnPrevInactive, setBtnPrevInactive] = useState(true)
  const [btnNextInactive, setBtnNextInactive] = useState(false)
  const [hoverSlider, setHoverSlider] = useState(false)
  const [rightHoverSlider, setRightHoverSlider] = useState(false)
  const [leftHoverSlider, setLeftHoverSlider] = useState(false)
  const [activateSlider, setActivateSlider] = useState(false)
  const [initAnimation, setInitAnimation] = useState(true)
  const [windowWidth, setWindowWidth] = useState(0)
  const [windowHeight, setWindowHeight] = useState(0)
  const [videoSrc, setVideoSrc] = useState(undefined)

  const itemsWrapper = useRef(null)
  const sliderInterval = useRef()
  const prevRef = useRef()
  const [updateSlider, setUpdateSlider] = useState('')

  const reverseIndex = i => {
    return i > 0 ? items.length - 1 - i : items.length - 1
  }

  useWindowSizeCache(({ width, height }) => {
    if (!initAnimation) {
      SetId(0)
      gone.clear()
      api.start(i => to(i))
      setActivateSlider(false)
      setTimeout(() => {
        setActivateSlider(true)
      }, 2000)
    }

    setWindowWidth(width)
    setWindowHeight(height)
  })

  const to = i => ({
    x: 0,
    y: '0%',
    delay: reverseIndex(i) * 200 + 1000,
    zIndex: items.length - i,
    opacity: 1,
  })

  const from = _i => ({
    x: 0,
    y: '100%',
    zIndex: items.length - _i,
    opacity: 1,
  })

  const [gone] = useState(() => new Set())

  const [props, api] = useSprings(items.length, i => ({
    ...to(i),
    from: from(i),
  }))

  // Gesture
  const bind = useGesture({
    // Drag
    onDrag: ({
      dragging,
      args: [index],
      movement: [mx],
      direction: [xDir],
      velocity: [velocity],
      tap,
      xy: [x],
      currentTarget,
    }) => {
      dragging && setHoverSlider(false)
      const trigger = velocity > 0.2
      const dir = xDir < 0 ? -1 : 1
      if (!dragging && trigger) gone.add(index)
      api.start(i => {
        if (index !== i) return
        const isGone = gone.has(index)
        const x = isGone ? (200 + window.innerWidth) * dir : dragging ? mx : 0
        const opacityValue = isGone ? 0 : 1
        isGone && i < items.length - 1 && SetId(i + 1)
        return {
          x,
          opacity: opacityValue,
          delay: undefined,
          config: { friction: 50, tension: dragging ? 800 : isGone ? 150 : 500 },
        }
      })
      if (!dragging && gone.size === items.length)
        setTimeout(() => {
          SetId(0)
          gone.clear()
          api.start(i => to(i))
          setActivateSlider(false)
          setTimeout(() => {
            setActivateSlider(true)
          }, 2000)
        }, 50)

      if (tap) {
        setHoverSlider(true)

        if (currentTarget.offsetWidth / 2 < x - currentTarget.getBoundingClientRect().x) {
          buttonOnClick(1)
        } else {
          buttonOnClick(-1)
        }
      }
    },
    //Hover
    onHover: ({ active }) => {
      activateSlider && !initAnimation && (active ? setHoverSlider(true) : setHoverSlider(false))
    },
    //Move
    onMove: ({ xy: [x], down, currentTarget, tap }) => {
      if (!down && hoverSlider) {
        if (currentTarget.offsetWidth / 2 < x - currentTarget.getBoundingClientRect().x) {
          // RIGHT
          if (!rightHoverSlider) {
            let itemIndex = 0
            api.start(i => {
              if (id === i) return
              if (id > i) return
              if (i > id + 2) return
              itemIndex += 1
              const x = 15 * itemIndex
              const y = `${0.85 * itemIndex}%`
              return {
                x,
                y,
                opacity: 1,
                delay: undefined,
                config: { friction: 70, tension: 500 },
              }
            })
            setRightHoverSlider(true)
            setLeftHoverSlider(false)
          }
        } else {
          // LEFT
          if (!leftHoverSlider) {
            let itemIndex = 0
            api.start(i => {
              if (id === i) return
              if (id > i) return
              if (i > id + 2) return
              itemIndex += 1
              const x = -15 * itemIndex
              const y = `${0.85 * itemIndex}%`
              return {
                x,
                y,
                opacity: 1,
                delay: undefined,
                config: { friction: 70, tension: 500 },
              }
            })
            setRightHoverSlider(false)
            setLeftHoverSlider(true)
          }
        }
      }
    },
  })

  const buttonOnClick = next => {
    if (next >= 1) {
      // next
      if (items.length - 1 > id) {
        setBtnPrevInactive(false)
        SetId(id + 1)
        gone.add(id)
        api.start(i => {
          if (id !== i) return
          const x = -200 - window.innerWidth
          return {
            x,
            opacity: 0,
            delay: undefined,
            config: { friction: 70, tension: 150 },
          }
        })
      }
    } else {
      // prev
      if (0 < id) {
        SetId(id - 1)
        gone.delete(id - 1)
        api.start(i => {
          if (id - 1 !== i) return
          const x = 0
          return {
            x,
            opacity: 1,
            delay: undefined,
            config: { friction: 70, tension: 200 },
          }
        })
      }
    }
  }

  const setSliderInterval = () => {
    sliderInterval.current = setInterval(() => {
      setUpdateSlider(Math.random())
    }, 7000)
  }

  useEffect(() => {
    if (updateSlider) {      
      if (id === items.length - 1) {        
        for (let index = 0; index < id; index++) {
          setTimeout(() => { prevRef.current.click() }, 500)
        }
        setSliderInterval();
      } else {
        buttonOnClick(1)
      }
    }
  }, [updateSlider])

  useEffect(() => {
    const timer = setTimeout(() => {
      setInitAnimation(false)
      setActivateSlider(true)
      setSliderInterval()
    }, 2500)

    return () => {
      clearTimeout(timer)
      clearInterval(sliderInterval.current)
    }
  }, [])

  useEffect(() => {
    id === 0 ? setBtnPrevInactive(true) : btnPrevInactive && setBtnPrevInactive(false)
    id === items.length - 1
      ? setBtnNextInactive(true)
      : btnNextInactive && setBtnNextInactive(false)
  }, [id, btnNextInactive, btnPrevInactive, items])

  useEffect(() => {
    if (!hoverSlider && !initAnimation) {
      setRightHoverSlider(false)
      setLeftHoverSlider(false)

      api.start(i => {
        if (id === i) return
        if (id > i) return
        if (i > id + 2) return
        const x = 0
        const y = '0%'
        return {
          x,
          y,
          opacity: 1,
          delay: undefined,
          config: { friction: 70, tension: 500 },
        }
      })
    }
  }, [hoverSlider, api, initAnimation])

  useEffect(() => {
    if (itemsWrapper) {
      if (windowWidth >= BREAKPOINTS.M) {
        const windowAspectRatio = ((windowHeight / windowWidth) * 100).toFixed(3)
        if (windowAspectRatio < 60) {
          if (windowAspectRatio < 35) {
            itemsWrapper.current.style.paddingTop = `20%`
          } else {
            itemsWrapper.current.style.paddingTop = `${windowAspectRatio - 12.5}%`
          }
        } else {
          itemsWrapper.current.style.paddingTop = '43.75%' // aspect-ratio: 16 / 7
        }
      } else {
        itemsWrapper.current.style.paddingTop = ''
      }
    }

    ScrollTrigger.refresh()
  }, [windowWidth, windowHeight])

  return (
    <section 
      className={clsx(s.hero, className)}
      onMouseEnter={() => clearInterval(sliderInterval.current)}
      onMouseLeave={() => setSliderInterval()}
    >
      <div className={isProjectDetailPage ? s.isProjectDetailPage : null}>
        <div className={innerClassName}>
          <div className={clsx(s.gridWrapper, initAnimation && s.gridWrapperOverflow)}>
            <ul className={s.itemsWrapper} ref={itemsWrapper}>
              {props.map(({ x, y, zIndex, opacity }, i) => {
                return (
                  <animated.li
                    key={i}
                    className={s.itemWrapper}
                    style={{ x, y, zIndex, opacity: opacity.to([0.35, 0.55], [0, 1]) }}
                  >
                    <div {...bind(i)} className={clsx(s.imageLink, s.desktop)}>
                      <Image
                        src={items[i].src}
                        alt={items[i].alt}
                        className={s.image}
                        layout='fill'
                        objectFit='cover'
                      />
                    </div>
                    <div {...bind(i)} className={clsx(s.imageLink, s.mobile)}>
                      <Image
                        src={items[i].srcMobile ? items[i].srcMobile : items[i].src}
                        alt={items[i].altMobile ? items[i].altMobile : items[i].alt}
                        className={s.image}
                        layout='fill'
                        objectFit='cover'
                      />
                    </div>
                    
                    { 
                      !!items[i]?.videoUrl ?
                      <PlayButton 
                        className='text-primary absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'
                        onClick={() => setVideoSrc(items[i]?.videoUrl)}
                      />
                    :
                      <>
                        {
                          !!items[i]?.video?.mediaItemUrl && 
                          <PlayButton 
                            className='text-primary absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'
                            onClick={() => setVideoSrc(items[i]?.video?.mediaItemUrl)}
                          /> 
                        }
                      </>
                    }
                    
                    {/* Card Container  */}
                    {items[i]?.card && (
                      <div className={clsx(s.cardContainer)}>
                        <Heading
                          text={items[i]?.card?.title}
                          semantics='h2'
                          className={s.cardTitle}
                        />
                        {items[i]?.card?.date && (
                          <Paragraph text={items[i]?.card?.date} className={s.cardDate} />
                        )}
                        <Paragraph text={items[i]?.card?.description} className={s.cardText} />
                      </div>
                    )}
                  </animated.li>
                )
              })}
            </ul>
          </div>
          <div className={clsx(s.menuSlider)}>
            <div className={clsx(s.menuSliderPrev, btnPrevInactive && s.btnInactive)}>
              <button ref={prevRef} className={s.arrowButton} onClick={() => {
                clearInterval(sliderInterval.current)
                buttonOnClick(-1)                
              }}>
                <span className={s.arrowIcon}>&#60;</span>
                <span className={s.title}>PREV</span>
              </button>
            </div>
            <div className={clsx(s.menuSliderCounter)}>
              {`${id < 9 ? '0' : ''}${id + 1} / ${items?.length < 10 ? '0' : ''}${items?.length}`}
            </div>

            {items[id]?.caption && (
              <div className={clsx(s.menuSliderStatus)}>{items[id]?.caption}</div>
            )}

            {items[id]?.project && (
              <div className={clsx(s.menuSliderProject)}>
                <span className={s.menuSliderTitle}>
                  {items[id]?.projectLabel ? items[id]?.projectLabel : 'View project:'}
                </span>{' '}
                <br />

                <Link
                  href={items[id]?.href ? typeof items[id]?.href === 'string' ? items[id]?.href : items[id]?.href[0].uri : '#'}
                  prefetch={false}
                >                  
                  <a target={items[id]?.target} className={s.menuSliderLink}>
                    <span>{items[id]?.project}</span>
                    {/* <span className={s.arrowIcon}>
                      <span className={s.arrowIconWrapper}>
                        <Arrow className={clsx('fill-current object-contain transform rotate-90')} />
                        <Arrow className={clsx('fill-current object-contain transform rotate-90')} />
                      </span>
                    </span> */}
                  </a>
                </Link>
              </div>
            )}

            {items[id]?.status && (
              <div className={clsx(s.menuSliderStatus)}>
                <span className={s.menuSliderTitle}>Status:</span> <br />
                {items[id]?.status}
              </div>
            )}

            <div className={clsx(s.menuSliderNext, btnNextInactive && s.btnInactive)}>
              <button className={s.arrowButton} onClick={() => {
                clearInterval(sliderInterval.current)
                buttonOnClick(1)
              }}>
                <span className={s.title}>NEXT</span>
                <span className={s.arrowIcon}>&#62;</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <VideoModal
        isModalActive={!!videoSrc}
        onClose={() => setVideoSrc(undefined)}
        src={videoSrc}
      />
    </section>
  )
}

export default HeroSlider2
