import NextLink from 'next/link'
import { forwardRef } from 'react'

const Link = ({ href, shallow, replace, children, passHref }, ref) => {
  return (
    <NextLink
      legacyBehavior
      href={href}
      passHref={passHref}
      scroll={false}
      shallow={shallow}
      replace={replace}
      prefetch={false}>
      {children}
    </NextLink>
  )
}

export default forwardRef(Link)
