import clsx from 'clsx'
import React, { useRef, useEffect, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import s from '../mediaVideoCareers/MediaVideoCareers.module.scss'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import {
  Container,
  SubHeading,
  Heading,
  VideoModal,
  PlayButton,
  CustomImage,
  GridContainer,
  SectionContainer,
} from '../index'

import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const MediaText = ({
  subHeading,
  heading,
  image,
  video = false,
  aspectRatio,
  hasVideoPlayback = false,
}) => {
  const hLengthMax = useLengthOfLongestWord(heading)
  const imageRef = useRef()
  const q = gsap.utils.selector(imageRef)
  const tl = useRef()

  const [isVideoPlayerEnabled, setIsVideoPlayerEnabled] = useState(false)

  useEffect(() => {
    imageRef?.current && (tl.current = gsap.set(q('figure'), { scale: 1.1 }))

    imageRef?.current &&
      (tl.current = gsap
        .timeline({
          defaults: { duration: 1, ease: 'bigd-ease-out' },
          scrollTrigger: {
            trigger: imageRef?.current,
            start: '30% 100%',
          },
        })
        .to(q('figure'), { scale: 1 }))
  }, [])

  return (
    <SectionContainer className='pt-80 md:pt-0'>
      {heading && subHeading && (
        <GridContainer className='mx-16 md:mx-30'>
          {/* A - Heading & Subheading  */}
          <div className='col-span-4 md:col-span-7 md:col-start-1.5'>
            <SubHeading text={subHeading} className='mb-40 md:mb-35' />

            <div className='mb-60 md:mb-130'>
              <Heading
                semantics='h2'
                text={heading}
                className={clsx(
                  'tracking-4 text-4xl-D uppercase md:text-8xl',
                  hLengthMax > 10 ? 'h2-scale-down-A' : hLengthMax > 8 && 'h2-scale-down-B'
                )}
                indent='22'
              />
            </div>
          </div>
        </GridContainer>
      )}
      <Container>
        <GridContainer className='relative -mx-16 md:-mx-30'>
          <ImageComponent
            image={image}
            aspectRatio={aspectRatio}
            imageRef={imageRef}
            hasVideoPlayback={hasVideoPlayback}
            setIsVideoPlayerEnabled={setIsVideoPlayerEnabled}
          />

          <VideoModal
            id='joinUsCareersVideo'
            isModalActive={isVideoPlayerEnabled}
            onClose={() => setIsVideoPlayerEnabled(false)}
            src={video}
          />
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

// export default MediaText;
export default React.memo(MediaText)

const ImageComponent = ({
  image,
  aspectRatio,
  imageRef,
  hasVideoPlayback = true,
  setIsVideoPlayerEnabled,
}) => {
  return (
    <div
      className={clsx(
        'relative col-span-4 h-full overflow-hidden md:col-span-9 md:mr-0',
        'flex items-center justify-center cursor-pointer',
        hasVideoPlayback && s.play_btn
      )}
      ref={imageRef}
      onClick={() => {
        hasVideoPlayback && setIsVideoPlayerEnabled(true)
      }}>
      <CustomImage
        src={image?.sourceUrl}
        alt={image?.altText}
        className='w-full h-full bg-image object-cover object-center'
        sizes='(max-width: 768px) 100vw, 50vw'
        aspectRatio={aspectRatio}
      />
      <PlayButton />
    </div>
  )
}
