import clsx from 'clsx'
import { useRouter } from 'next/router'
import Styles from './ItemsRenderer.module.scss'
import { Link, Dash } from '../../index'

/**
 * Render Navigation Items based on supplied list
 */
const ItemsRenderer = ({
  list,
  className,
  anchorClassName,
  itemClickHandler,
  showActiveDash = false,
  isFooter = false,
  ariaLabelledBy,
}) => {
  const router = useRouter()
  const routerPathName = router?.asPath

  return (
    <ul className={className} aria-labelledby={ariaLabelledBy}>
      {list?.map(item => {
        let href = item?.path
        if (href.slice(-1) == '/') {
          href = href.substring(0, href.length - 1)
        }

        const isActiveClass = routerPathName === href

        return (
          <li key={item?.id} onClick={isActiveClass ? null : itemClickHandler}>
            <Link href={href}>
              <a
                target={item?.target}
                className={clsx(
                  anchorClassName,
                  'py-8 md:pr-0 md:py-0 font-light',
                  Styles.textPrimary,
                  isActiveClass && 'flex items-center pointer-events-none',
                  isActiveClass && isFooter && Styles.active // styles exclusive to footer
                )}>
                {/* Show dash before active page on MegaMenu */}
                {isActiveClass && showActiveDash && (
                  <Dash className='h-2 bg-secondary' variation='short' />
                )}
                {item?.label}
              </a>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default ItemsRenderer
