import clsx from 'clsx'
import { gsap } from 'gsap'
import { useEffect, useRef } from 'react'

import {
  Heading,
  Paragraph,
  CustomImage,
  GridContainer,
  SectionHeading,
  SectionContainer,
} from '../../components'

import useIntroEnded from '../../hooks/useIntroEnded'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

import Styles from './HeroProject.module.scss'

function useArrayRef() {
  const refs = useRef([])
  refs.current = []
  return [refs, ref => ref && refs?.current?.push(ref)]
}

const HeroProject = ({ title, subtitle, details, image }) => {
  const headingRef = useRef()
  const subHeadingRef = useRef()
  const imageRef = useRef()
  const [detailsRef, setDetailsRef] = useArrayRef()
  const tl = useRef()
  const tlp = useRef()
  const q = gsap.utils.selector(imageRef)

  const { introEnded } = useIntroEnded()

  useEffect(() => {
    tl.current = gsap
      .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
      .set(subHeadingRef?.current, { yPercent: 100 })
      .set(detailsRef?.current, { y: 15, opacity: 0 })
      .set(imageRef?.current, { opacity: 0, y: 10 })

    imageRef?.current &&
      (tlp.current = gsap
        .timeline({
          defaults: { duration: 1, ease: 'bigd-ease-out' },
          scrollTrigger: {
            trigger: imageRef?.current,
            scrub: true,
            invalidateOnRefresh: true,
          },
        })
        .fromTo(
          q('.customImage > figure'),
          {
            scale: 1.2,
            yPercent: 10,
          },
          {
            scale: 1.2,
            yPercent: -10,
          }
        ))
  }, [])

  useEffect(() => {
    if (introEnded) {
      tl.current = gsap
        .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
        .addLabel('init')
        .call(() => headingRef?.current?.animateIn(), null, 'init+=.2')
        .to(subHeadingRef?.current, { yPercent: 0 }, 'init+=.8')
        .to(detailsRef?.current, { y: 0, opacity: 1, stagger: 0.15 }, 'init+=1')
        .to(imageRef?.current, { opacity: 1, y: 0 }, 'init+=1.2')
    }
  }, [introEnded])

  const hLengthMax = useLengthOfLongestWord(title)

  return (
    <SectionContainer className='hero-pt mb-80 md:mb-150'>
      <GridContainer className='mb-76 md:mb-130'>
        <SectionHeading
          position='center'
          className={clsx(
            hLengthMax > 10
              ? 'col-span-4 md:col-span-9'
              : 'col-span-4 md:col-span-7 md:col-start-1',
            Styles.sectionHeading
          )}>
          <Heading
            semantics='h1'
            text={title}
            className={clsx(
              'tracking-4 mb-16 text-center text-white uppercase md:text-left',
              'text-6xl md:text-9xl',
              hLengthMax > 10 ? 'h1-scale-down-A' : hLengthMax > 6 && 'h1-scale-down-B'
            )}
            indent='align-right'
            buildUpAnimation={true}
            ref={headingRef}
          />
          <div className='overflow-hidden'>
            <Heading
              semantics='h3'
              text={subtitle}
              className='tracking-4 mb-10 text-right text-2xl-F uppercase md:mb-4 md:text-4xl-A'
              ref={subHeadingRef}
            />
          </div>
        </SectionHeading>
      </GridContainer>

      <div className='flex mb-10'>
        {details &&
          details.map((i, index) => (
            <div
              key={index}
              className={clsx('mr-35 md:mr-80', !i?.detail && 'hidden')}
              ref={setDetailsRef}>
              <span className='text-right text-white text-xss-B uppercase'>{i?.title}</span>

              <Heading
                semantics='h3'
                text={i?.detail}
                className='tracking-4 text-white text-base-A md:text-3xl-A'
              />
            </div>
          ))}
      </div>

      <div ref={imageRef}>
        <CustomImage
          src={image?.sourceUrl}
          alt={image?.altText}
          width='1600'
          height='943'
          className='customImage -mx-16 bg-image overflow-hidden md:-mx-30'
          aspectRatio='aspect-w-16 aspect-h-9'
        />
      </div>
    </SectionContainer>
  )
}

export default HeroProject
