import { Link } from '../index'

import LogoPrimary from '../../../public/svg/logo.svg'
// White Logo - Secondary - Footer
import LogoSecondary from '../../../public/svg/logo.svg'

const Logo = ({ primary = true, onClick, tabIndex }) => (
  <Link href={'/'}>
    <a onClick={onClick} tabIndex={tabIndex}>
      {primary ? (
        <LogoPrimary className='h-auto' style={{ width: '24rem' }} />
      ) : (
        <LogoSecondary className='md:w-123 w-130 h-auto' />
      )}
      <span className='sr-only'>Big-D Dovetail / Resorts &amp; Residences</span>
    </a>
  </Link>
)

export default Logo
