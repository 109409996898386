import clsx from 'clsx'
import Loader from '../../../public/icons/loader.svg'
import Copy from '../../../public/icons/copy-icon.svg'
import Plus from '../../../public/icons/plus-icon.svg'
import Minus from '../../../public/icons/minus-icon.svg'
import Email from '../../../public/icons/email-icon.svg'
import Arrow from '../../../public/icons/arrow-right.svg'
import Twitter from '../../../public/icons/twitter-icon.svg'
import MenuClose from '../../../public/icons/menu-close.svg'
import MenuExpand from '../../../public/icons/menu-expand.svg'
import Facebook from '../../../public/icons/facebook-icon.svg'
import Instagram from '../../../public/icons/instagram-icon.svg'

const Icon = ({
  name = 'Arrow',
  className = '',
  iconClassName = 'fill-current object-contain', // maybe remove this
  clickHandler = () => {},
  border = true,
  borderClassName = '',
}) => {
  let Icon

  switch (name) {
    case 'arrow':
      Icon = <Arrow className={`w-full ${iconClassName}`} />
      break

    case 'copy':
      Icon = <Copy className={`w-12 ${iconClassName}`} />
      break

    case 'email':
      Icon = <Email className={`w-12 ${iconClassName}`} />
      break

    case 'menuClose':
      Icon = <MenuClose className={iconClassName} />
      break

    case 'menuExpand':
      Icon = <MenuExpand className={iconClassName} />
      break

    case 'facebook':
      Icon = <Facebook className={iconClassName} />
      break

    case 'instagram':
      Icon = <Instagram className={iconClassName} />
      break

    case 'twitter':
      Icon = <Twitter className={iconClassName} />
      break

    case 'loader':
      Icon = <Loader className={`animate-ping ${iconClassName}`} />
      break

    case 'plus':
      Icon = <Plus className={`w-12 md:w-20 ${iconClassName}`} />
      break

    case 'minus':
      Icon = <Minus className={`w-12 md:w-20 ${iconClassName}`} />
      break

    default:
      Icon = <Arrow className={iconClassName} />
      break
  }

  return (
    <span
      onClick={clickHandler}
      className={clsx(
        'w-28 h-28',
        'pointer flex items-center justify-center',
        // 'hover:text-secondary hover:bg-primary',
        'h-contrast',
        border && `border ${borderClassName ? borderClassName : 'border-primary-light'} rounded-full`,
        className
      )}>
      {Icon}
    </span>
  )
}

export default Icon
