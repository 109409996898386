import clsx from 'clsx'
import { Icon } from '../../index.js'
import { useEffect, useState } from 'react'
import Styles from './CopyButton.module.scss'

const CopyButton = ({ text, className = '' }) => {
  const [displayText, setDisplayText] = useState(text)

  const buttonClickHandler = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setDisplayText('URL Copied')
        // swap back the original text after 6 seconds
        setTimeout(() => setDisplayText(text), 6000)
      })
      .catch(err => alert('Failed to copy.', err))
  }

  return (
    <div className={`flex md:justify-end ${className}`}>
      <div
        className={clsx(Styles.copy_button, 'flex items-center cursor-pointer')}
        onClick={buttonClickHandler}>
        <p className='mr-10 text-base-A -tracking-3 md:text-2xl-C'>{displayText}</p>

        <button
          aria-label='Copy'
          className={clsx(
            'pointer flex items-center justify-center rounded-full focus-visible:outline-black focus:outline-none',
            'w-38 h-38'
          )}>
          <Icon name='copy' border={false} />
          <span className='sr-only'>Copy button</span>
        </button>
      </div>
    </div>
  )
}

export default CopyButton
