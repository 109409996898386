import { useCallback, useEffect } from 'react'

const callbacks = new Set()

const fireCallbacks = (isLastTick = false) => {
  callbacks.forEach(setter => {
    setter({ isLastTick })
  })
}

let isThrottled = false
let timeoutId = null
const DEBOUNCE_MS = 500

const handleResize = () => {
  if (!isThrottled) {
    fireCallbacks()

    isThrottled = true

    setTimeout(() => {
      isThrottled = false
    }, DEBOUNCE_MS)
  }

  clearTimeout(timeoutId)

  timeoutId = setTimeout(() => {
    fireCallbacks(true)
  }, DEBOUNCE_MS)
}

let isListenerActive = false

/**
 * @hook Calls a callback function on window resize
 */
export const useResize = (callback, dependencies) => {
  const cb = useCallback(callback, dependencies)

  useEffect(() => {
    callbacks.add(cb)

    if (!isListenerActive) {
      window.addEventListener('resize', handleResize)
      isListenerActive = true
    }
    return () => {
      callbacks.delete(cb)

      if (callbacks.size === 0) {
        window.removeEventListener('resize', handleResize)
        isListenerActive = false
      }
    }
  }, [cb])
}
