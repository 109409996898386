import { getRandomArrayItems } from './index'
import { RECOMMENDATION_POSTS_COUNT } from './siteConfig'

// Generate Random News for Recommendation
const generateNewsRecommendation = (allNews, currentNews, preview) => {
  if (preview) {
    // Simply return random news from allNews
    // no need to do any additional filtering
    return allNews?.allNewsPosts?.length > RECOMMENDATION_POSTS_COUNT
      ? getRandomArrayItems(allNews?.allNewsPosts, RECOMMENDATION_POSTS_COUNT)
      : allNews?.allNewsPosts
  }

  const currentCategorySlug =
    currentNews?.categories?.nodes?.length > 0 ? currentNews?.categories?.nodes[0]?.slug : ''

  // #1 Filter out current news & category
  const allNewsExceptCurrent = allNews?.allNewsPosts?.filter(news => {
    let isCurrentCategory = false
    if (news?.categories?.nodes?.length > 0) {
      isCurrentCategory = news?.categories?.nodes[0]?.slug == currentCategorySlug
    }

    return news?.id !== currentNews?.id && isCurrentCategory
  })

  // #2 Shuffle and get random news
  return allNewsExceptCurrent?.length > RECOMMENDATION_POSTS_COUNT
    ? getRandomArrayItems(allNewsExceptCurrent, RECOMMENDATION_POSTS_COUNT)
    : allNewsExceptCurrent
}

export default generateNewsRecommendation
