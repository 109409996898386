import clsx from 'clsx'

import { Heading, SubHeading, CustomImage, LinkWrapper, GridContainer } from '../index'
import Styles from './NewsCard.module.scss'

// News-Card
const NewsCard = ({
  post,
  image,
  imageAspectRatio,
  imageClassName,
  customImageClassName,
  subHeading,
  href,
  target,
  linkWrapperClassName = '',
}) => {
  // const imageUrl = post?.signaturePostNewsFields?.headerImage?.sourceUrl;
  // const imageAlt = post?.signaturePostNewsFields?.headerImage?.altText;

  return (
    <div className={Styles.card}>
      <LinkWrapper href={href} target={target} className={linkWrapperClassName}>
        <GridContainer className={Styles.container}>
          <div className='md:col-start-0 hidden col-span-2 items-center justify-start md:flex'>
            <CustomImage
              className={clsx(customImageClassName)}
              imageClassName={imageClassName}
              // className={Styles.cardImage}
              image={image}
              // sizes='(max-width: 768px) 100vw, 50vw'
              dataPreload={false}
              aspectRatio={imageAspectRatio}
              objectProp='object-contain'
            />
          </div>

          <div
            className={clsx(
              'flex flex-col col-span-4 pr-30 py-30 md:col-start-2 md:py-70',
              Styles.copy
            )}>
            <SubHeading text={subHeading} className={clsx('pb-20 md:pb-35', Styles.subheading)} />

            <Heading
              semantics='h2'
              text={post.title}
              className='tracking-4 text-primary text-2xl-F uppercase md:text-4xl-B'
            />
          </div>
        </GridContainer>
      </LinkWrapper>
    </div>
  )
}

export default NewsCard
