import clsx from 'clsx'

const PlayButton = ({ text = 'Play Video', className, onClick }) => {
  return (
    <button
      onClick={onClick}
      aria-label='Play Video'
      className={clsx(
        'absolute',
        'w-100 h-100 bg-white rounded-full md:w-175 md:h-175',
        'flex items-center justify-center',
        'focus-visible:outline-black focus:outline-none',
        className
      )}>
      <span className='tracking-1.5 text-xs-A uppercase md:text-2xl-B'>{text}</span>
    </button>
  )
}

export default PlayButton
