import clsx from 'clsx'
import { useRef, useEffect } from 'react'
import Image from 'next/legacy/image'
import { IMG_LAZY_LOAD_THRESHOLD } from '../../utils/siteConfig'
import Styles from './threeByTwoGrid.module.scss'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import {
  Heading,
  CopyGroup,
  Container,
  Accordian,
  Paragraph,
  // TicTacToe,
  SubHeading,
  GridContainer,
  SectionContainer,
} from '../index'

const ThreeByTwoGrid = ({
  heading,
  subHeading,
  subHeadingclassName = '', // in case you want to extend the current styles
  image,
  description,
  gridItems,
  className = '',
  imagePosition,
  background = 'bg-white',
}) => {
  const GridRef = useRef()
  const imageCover = useRef(false)
  const tl = useRef()
  const items = gridItems?.length

  useEffect(() => {
    imageCover.current &&
      (tl.current = gsap
        .timeline({
          defaults: { duration: 1, ease: 'bigd-ease-out' },
          scrollTrigger: {
            trigger: imageCover.current,
            start: '30 100%',
          },
        })
        .to(imageCover.current, {
          xPercent: imagePosition === 'left' ? -100 : 100,
        }))
  }, [])

  return (
    <SectionContainer className={clsx(className, background)}>
      <Container>
        <GridContainer className='gap-y-0 mb-20 md:mb-100'>
          {imagePosition ? (
            <div
              className={clsx(
                'relative col-span-4.5 row-start-2 overflow-hidden md:row-start-auto',
                imagePosition === 'left' && 'md:col-start-0 md:col-span-4.5 md:-ml-50 md:mr-0',
                imagePosition === 'right' && 'md:col-start-6 md:col-span-3 md:-mr-50 md:ml-0',
                '-ml-16 -mr-16 '
              )}>
              <figure className='w-full h-auto object-cover'>
                {image?.sourceUrl && (
                  <>
                    <Image
                      className='w-full h-auto object-cover'
                      src={image?.sourceUrl}
                      alt={image?.altText}
                      width={imagePosition === 'left' ? 777 : 531}
                      height={imagePosition === 'left' ? 704 : 816}
                      sizes='(max-width: 768px) 100vw, 50vw'
                      layout='responsive'
                      lazyBoundary={IMG_LAZY_LOAD_THRESHOLD}
                    />
                    <div
                      ref={imageCover}
                      className={clsx(
                        'absolute top-0 w-1/2 h-full',
                        background,
                        imagePosition === 'left' && 'origin-left left-0',
                        imagePosition === 'right' && 'origin-right right-0'
                      )}></div>
                  </>
                )}
              </figure>
            </div>
          ) : null}

          <div
            className={clsx(
              'flex flex-col col-span-4 justify-center',
              imagePosition === 'left' && ' md:col-start-5 md:-ml-20',
              imagePosition === 'right' && 'md:col-start-0.5 md:col-span-5.5 row-start-1',
              !imagePosition && 'md:col-start-0.5 md:col-span-full row-start-1'
            )}>
            <SubHeading
              text={subHeading}
              className={clsx('mb-40 md:mb-35', subHeadingclassName)}
              onScrollAnimation={true}
            />

            <Heading
              semantics='h2'
              text={heading}
              className='heading mb-40 text-4xl-D -tracking-4 uppercase md:mb-100 md:text-8xl md:-tracking-7'
              indent='15'
              onScrollAnimation={true}
            />

            <Paragraph
              className='mb-40 break-words md:mb-0 md:ml-80 md:pr-30 md:max-w-2xl'
              text={description}
            />
          </div>
        </GridContainer>

        {/* 3x3 Grid */}

        {/* TODO: fix Animation issues on TicTacToe component */}
        {/* <TicTacToe gridItems={gridItems} ref={GridRef} /> */}

        {/* <div
          className={clsx(
            Styles.customGrid,
            items <= 3 && Styles.threeItems,
            items == 4 && Styles.fourItems,
            (items == 5 || items == 6) && Styles.fiveOrSixItems,
            items >= 7 && Styles.sevenOrMoreItems
          )}
          ref={GridRef}>
          {gridItems?.map((i, index) => (
            <CopyGroup
              key={index}
              index={index + 1}
              className={clsx(
                'col-span-4 pr-20 py-40 md:col-span-3 md:px-100 md:py-70'
              )}
              heading={i?.title}
              description={i?.description}
              onScrollAnimationTrigger={GridRef}
            />
          ))}
        </div> */}

        {/* Accordian Component */}
        <GridContainer>
          <Accordian className='col-span-4' items={gridItems} firstItemOpen={true} />
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default ThreeByTwoGrid
