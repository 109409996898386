import clsx from 'clsx'
import React, { useRef, useEffect, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import s from '../mediaVideo/MediaVideo.module.scss'
import Style from './MediaText.module.scss'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import {
  Cta,
  Dash,
  Heading,
  Paragraph,
  CopyGroup,
  Container,
  SubHeading,
  VideoModal,
  PlayButton,
  CustomImage,
  GridContainer,
  SectionContainer,
} from '../index'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const MediaText = ({
  cta,
  image,
  secondaryImage,
  imageTitle,
  imageSubtitle,
  imageHeadingClass,
  video = false,
  heading,
  subHeading,
  description,
  imagePosition = 'left',
  aspectRatio,
  hasVideoPlayback = false,
  gridContainerClassName,
  sectionContainerClassName,
  isContactPage = false,
  isHomePage = false,
}) => {
  const copyPosition = imagePosition === 'left' ? 'right' : 'left'
  const imageRef = useRef()
  const q = gsap.utils.selector(imageRef)
  const tl = useRef()

  const [isVideoPlayerEnabled, setIsVideoPlayerEnabled] = useState(false)

  useEffect(() => {
    imageRef?.current && (tl.current = gsap.set(q('figure'), { scale: 1.1 }))

    imageRef?.current &&
      (tl.current = gsap
        .timeline({
          defaults: { duration: 1, ease: 'bigd-ease-out' },
          scrollTrigger: {
            trigger: imageRef?.current,
            start: '30% 100%',
          },
        })
        .to(q('figure'), { scale: 1 }))
  }, [])

  return (
    <SectionContainer className={clsx('pt-80 bg-background md:pt-0', sectionContainerClassName)}>
      <Container className='py-0'>
        <GridContainer className={clsx('grid-flow-row items-center md:grid-flow-col md:py-150', gridContainerClassName)}>
          {/* A - LEFT Image  */}
          <ImageComponent
            imagePosition={imagePosition}
            image={image}
            secondaryImage={secondaryImage}
            imageTitle={imageTitle}
            imageSubtitle={imageSubtitle}
            imageHeadingClass={imageHeadingClass}
            aspectRatio={aspectRatio}
            imageRef={imageRef}
            hasVideoPlayback={hasVideoPlayback}
            setIsVideoPlayerEnabled={setIsVideoPlayerEnabled}
          />

          {/* B - RIGHT - Copy */}
          <CopyComponent
            cta={cta}
            heading={heading}
            subHeading={subHeading}
            description={description}
            copyPosition={copyPosition}
            isContactPage={isContactPage}
            isHomePage={isHomePage}
          />

          <VideoModal
            id='joinUsCareersVideo'
            isModalActive={isVideoPlayerEnabled}
            onClose={() => setIsVideoPlayerEnabled(false)}
            src={video}
          />
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

// export default MediaText;
export default React.memo(MediaText)

const ImageComponent = ({
  imagePosition,
  image,
  secondaryImage,
  imageTitle,
  imageSubtitle,
  imageHeadingClass,
  aspectRatio,
  imageRef,
  hasVideoPlayback = false,
  setIsVideoPlayerEnabled,
}) => {
  return (
    <div
      className={clsx(
        'relative col-span-4 -ml-16 -mr-16 h-full overflow-hidden md:mr-0',
        imagePosition === 'left' && 'md:col-start-0 md:-ml-50 md:mr-0',
        imagePosition === 'right' && 'md:col-start-5 md:ml-0 md:-mr-50',
        'flex items-center justify-center cursor-pointer flex-col',
        hasVideoPlayback && s.play_btn
      )}
      ref={imageRef}
      onClick={() => {
        hasVideoPlayback && setIsVideoPlayerEnabled(true)
      }}>
      <CustomImage
        src={image?.sourceUrl}
        alt={image?.altText}
        className='w-full h-full object-cover object-center'
        sizes='(max-width: 768px) 100vw, 50vw'
        aspectRatio={aspectRatio}
      />
      
      {secondaryImage && 
        <CustomImage
          src={secondaryImage?.sourceUrl}
          alt={secondaryImage?.altText}
          className='w-full mt-30'
          sizes='(max-width: 768px) 100vw, 50vw'
          aspectRatio={'aspect-w-3 aspect-h-2'}
          objectProp={'object-auto'}
        />
      }

      {hasVideoPlayback && <PlayButton />}

      { imageTitle && imageSubtitle && 
        <div className={clsx(`absolute text-white`, imageHeadingClass ? imageHeadingClass : `top-200 left-20 md:left-40`)}>
          <Heading semantics={'h3'} text={imageTitle} className={'text-5xl tracking-4 uppercase'} onScrollAnimation={true} />
          <Heading semantics={'h4'} text={imageSubtitle} className='text-2xl-A tracking-4' dash={false} onScrollAnimation={true} />
        </div>
      }
    </div>
  )
}

const CopyComponent = ({ copyPosition, cta, heading, subHeading, description, isContactPage = false, isHomePage = false }) => {
  const hLengthMax = useLengthOfLongestWord(heading)

  return (
    <div
      className={clsx(
        'flex col-span-4 items-center self-stretch md:col-span-4.5',
        'row-start-1 md:row-start-auto',
        copyPosition === 'left' && 'md:col-start-0.5 row-start-0 md:row-start-0',
        copyPosition === 'right' && 'md:col-start-4.5'
      )}>
      <div>
        <SubHeading text={subHeading} className={clsx('mb-40', isContactPage ? 'md:mb-55': 'md:mb-35')} onScrollAnimation={true} />

        <Heading
          semantics='h2'
          text={heading}
          className={clsx(
            'heading tracking-4 text-4xl-D uppercase',
            isContactPage ? 'md:text-4xl-B' : (isHomePage ? 'md:text-7xl' : 'md:text-8xl'),
            hLengthMax > 10 ? 'h2-scale-down-A' : hLengthMax > 8 && 'h2-scale-down-B'
          )}
          onScrollAnimation={true}
        />

        { !isContactPage && description ? (
          <div className={clsx('flex items-start mb-10 pr-16', '2xl:mr-160 md:ml-50 md:mr-100  ')}>
            <Dash variation='short' className='hidden pt-10 md:block md:mr-85' />

            <div>
              {Array.isArray(description) ? (
                <>
                  {description?.length &&
                    description?.map((i, index) => (
                      <CopyGroup
                        key={index}
                        index={index + 1}
                        heading={i?.title}
                        description={i?.description}
                      />
                    ))}
                </>
              ) : (
                <Paragraph text={description} className='paragraph mb-40 md:mb-80' />
              )}

              <Cta cta={cta} className='hidden md:inline-block' />
            </div>
          </div>
        ) : null}

        { isContactPage && description ? (
          <div className={clsx('flex items-start flex-col mb-10')}>
            <Paragraph text={description} className={clsx('paragraph md:text-3xl-A mt-20 mb-40 md:mb-80 text-white block', Style.textWhite)} />
            <Cta cta={cta} className='hidden md:inline-block' />            
          </div>
        ) : null}
      </div>
    </div>
  )
}
