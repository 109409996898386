import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { BackgroundTintLayer } from '../index'
import MenuClose from '../../../public/icons/menu-close.svg'
import { getEmbedUrlAndPlatform } from '../../utils'

import s from './Modal.module.scss'

// BIO Description Modal
const VideoModal = ({
  id = 'videoModal', // unique required
  isModalActive,
  onClose,
  backgroundColor,
  src,
}) => {
  const [hidden, setHidden] = useState(true)
  const [embededVideoUrl, setEmbededVideoUrl] = useState(null)
  const [platform, setPlatform] = useState(null)

  useEffect(() => {
    if (src) {
      const { embededUrl, platform } = getEmbedUrlAndPlatform(src, 0)
      setEmbededVideoUrl(embededUrl)
      setPlatform(platform)
    }
  }, [src])

  useEffect(() => {
    if (isModalActive && typeof window !== 'undefined') {
      const video = document?.getElementById(id)
      if (video) {
        video?.play()
      }
    }

    if (!isModalActive) {
      setHidden(true)
    } else {
      const timer = setTimeout(() => {
        setHidden(false)
      }, 300)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [isModalActive])

  const closeModal = () => {
    const video = document?.getElementById(id)    

    if (video) {
      video?.pause()
      video.currentTime = 0
    }
    
    onClose()
  }

  if (!src) return null
  return (
    <div
      className={clsx(
        'fixed z-40 left-0 top-0 w-screen h-full bg-transparent',
        isModalActive && 'items-center justify-center md:flex ',
        !isModalActive && 'hidden'
      )}>
      {/* Modal Background Tint */}
      <BackgroundTintLayer
        onClick={closeModal}
        active={isModalActive}
        backgroundColor={backgroundColor}
        opacity='opacity-60'
      />

      {/* Modal */}
      <div
        className={clsx(
          s.modalVideo,
          'transition duration-300',
          hidden ? 'opacity-0' : 'opacity-100'
        )}
        onClick={event => event.stopPropagation()}
        role='dialog'>
        <button
          onClick={closeModal}
          aria-label='Close'
          className={clsx(
            s.button,
            'absolute z-50 right-30 top-30 md:right-20 md:top-20',
            'flex items-center justify-center',
            'rounded-full focus-visible:outline-black focus:outline-none cursor-pointer',
            'w-32 h-32 md:w-38 md:h-38',
            'text-primary',
            'h-contrast'
          )}>
          <MenuClose className='w-14 h-14 fill-current md:w-14 md:h-14' />
        </button>

        {src && !platform && (
          <video controls id={id} className='w-full'>
            <source src={src} type='video/mp4' />
          </video>
        )}

        {platform == 'youtube' && isModalActive &&
          <iframe
            id='videoIframe'
            src={embededVideoUrl}
            className='w-full'
            title=''
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen></iframe>
        }

        {platform == 'vimeo' && isModalActive &&
          <iframe
            id='videoIframe'
            src={embededVideoUrl}
            className='w-full'
            width='1920'
            height='1080'
            frameBorder='0'
            allow='autoplay; fullscreen; picture-in-picture'
            allowFullScreen></iframe>
        }
      </div>
    </div>
  )
}

export default VideoModal
