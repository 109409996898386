import Head from 'next/head'

const GlobalMeta = () => (
  <Head>
    {/* Add your global font imports here */}
    <link
      rel='preload'
      href='/fonts/bebas-neue-regular.woff2'
      as='font'
      type='font/woff2'
      crossOrigin='anonymous'
    />
    <link
      rel='preload'
      href='/fonts/roboto-medium.woff2'
      as='font'
      type='font/woff2'
      crossOrigin='anonymous'
    />
    <link
      rel='preload'
      href='/fonts/roboto-light.woff2'
      as='font'
      type='font/woff2'
      crossOrigin='anonymous'
    />    

    {/* PWA */}
    <meta name='application-name' content='BigD - Dovetail Construction' />
    <meta name='apple-mobile-web-app-capable' content='yes' />
    <meta name='apple-mobile-web-app-status-bar-style' content='default' />
    <meta name='apple-mobile-web-app-title' content='BigD - Dovetail Construction' />
    <meta name='format-detection' content='telephone=no' />
    <meta name='mobile-web-app-capable' content='yes' />
    <meta name='msapplication-config' content='/icons/browserconfig.xml' />
    <meta name='msapplication-TileColor' content=' #1a1a1a' />
    <meta name='msapplication-tap-highlight' content='no' />
    <meta name='theme-color' content=' #1a1a1a' />

    <link rel='apple-touch-icon' href='/images/192.png' />
    <link rel='apple-touch-icon' sizes='192x192' href='/images/192.png' />
    <link rel='apple-touch-icon' sizes='384x384' href='/images/384.png' />
    <link rel='apple-touch-icon' sizes='512x512' href='/images/512.png' />

    <link rel='icon' type='image/png' sizes='32x32' href='/images/32.png' />
    <link rel='icon' type='image/png' sizes='16x16' href='/images/16.png' />
    <link rel='manifest' href='/manifest.json' />
    <link rel='mask-icon' href='/images/192.png' />
    <link rel='shortcut icon' href='/images/192.png' />
  </Head>
)

export default GlobalMeta
