import clsx from 'clsx'
import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import SplitText from '../../utils/SplitText/SplitText'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(SplitText, ScrollTrigger)
}

import {
  Heading,
  Paragraph,
  Container,
  SubHeading,
  CustomImage,
  GridContainer,
  SectionContainer,
} from '../index'

const ContentMediaText = ({
  headline,
  title,
  description,
  image,
  imageAlignment,
  imageCaption,
}) => (
  <SectionContainer className='mb-80 md:mb-150'>
    <Container>
      {imageAlignment == 'left' ? (
        <GridContainer className={clsx(image && ' items-center ')}>
          {image && (
            <CustomImage
              className={clsx('col-span-4 -mx-32 mb-80 md:-mx-30 md:mb-0')}
              imageClassName='bg-image'
              src={image.sourceUrl}
              alt={image.altText}
              aspectRatio='aspect-w-3 aspect-h-4'
              captionClassName='pr-32 md:pr-16 text-xs-caption -tracking-3 md:text-sm-A'
              caption={imageCaption}
            />
          )}

          <Copy
            className={clsx(
              'col-span-4 md:col-span-3',
              image && ' md:col-start-5 ',
              !image && ' col-start-0 md:col-start-2 '
            )}
            subHeading={headline}
            heading={title}
            para={description}
          />
        </GridContainer>
      ) : (
        <GridContainer className={clsx('mb-0 md:mb-150', image && ' items-center ')}>
          <Copy
            className={clsx(
              'col-start-0 col-span-4 md:col-span-3 ',
              image && ' md:col-start-1 mb-30',
              !image && ' md:col-start-2 '
            )}
            subHeading={headline}
            heading={title}
            para={description}
          />

          {image && (
            <CustomImage
              className={clsx('col-span-4 md:col-start-5  ', '-mx-32 mt-40 md:-mx-30 md:mt-0')}
              imageClassName='bg-image'
              aspectRatio='aspect-w-3 aspect-h-4'
              captionClassName='pr-32 md:pr-16 md:pr-0 text-xs-caption -tracking-3 md:text-sm-A'
              caption={imageCaption}
              src={image.sourceUrl}
              alt={image.altText}
              sizes='(max-width: 768px) 100vw, 50vw'
            />
          )}
        </GridContainer>
      )}
    </Container>
  </SectionContainer>
)

export default ContentMediaText

const Copy = ({ subHeading = '', heading = '', para = '', className = '' }) => {
  const CopyGroupRef = useRef()
  const q = gsap.utils.selector(CopyGroupRef)
  const tl = useRef()

  useEffect(() => {
    const title = q('h3 > p')
    if (title.length != 0) {
      // Split Text
      const splitText = new SplitText(title, {
        type: 'lines',
        linesClass: 'overflow-y-hidden pr-4',
      })

      new SplitText(title, {
        type: 'lines',
        linesClass: 'overflow-y-hidden',
      })

      gsap.set(splitText.lines, {
        yPercent: 100,
        overwrite: true,
      })

      ScrollTrigger.create({
        trigger: CopyGroupRef.current,
        start: 'top 80%',

        onEnter: () => {
          tl.current = gsap.timeline().to(splitText.lines, {
            yPercent: 0,
            duration: 1,
            stagger: 0.15,
            ease: 'bigd-ease-out',
            delay: 0.25,
            overwrite: true,
          })
        },
      })
    }
  }, [])

  return (
    <div ref={CopyGroupRef} className={className}>
      <SubHeading
        text={subHeading}
        className='mb-40 text-primary text-2xl-A uppercase md:mb-80 md:text-4xl-C'
        onScrollAnimation={true}
      />
      <Heading
        semantics='h3'
        text={heading}
        className='tracking-4 mb-40 w-full text-3xl-B uppercase md:text-4xl-B'
      />
      <div className={'paragraphContainer overflow-hidden'}>
        <Paragraph text={para} className={'paragraph'} />
      </div>
    </div>
  )
}
