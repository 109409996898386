/**
 * Generated suitable url for iframe-embeding purpose
 * @param {string} videoUrl - any standard youtube or vimeo video url
 * @returns {Object} videoUrlInfo - embededUrl & platform
 * 
 
VIMEO test urls
  https://vimeo.com/554570760
  https://www.vimeo.com/554570760
  https://vimeo.com/554570760?param=test
  https://vimeo.com/channels/staffpicks/554570760?autoplay=1
  https://vimeo.com/channels/staffpicks/554570760
  https://vimeo.com/groups/motion/videos/648237594

  https://vimeo.com/album/2222222/video/11111111
  http://vimeo.com/album/2222222/video/11111111

YOUTUBE test urls
  http://youtube.googleapis.com/v/4e_kz79tjb8?version=3
  http://youtu.be/Ab25nviakcw
  http://www.youtube.com/watch?v=Ab25nviakcw
  https://www.youtube.com/watch?v=BGL22PTIOAM&feature=g-all-xit
  https://www.youtube.com/watch?feature=g-vrec&v=Y1xs_xPb46M
  http://www.youtube.com/watch?feature=player_embedded&v=Ab25nviakcw#
*/
const getEmbedUrlAndPlatform = (videoUrl = '', autoplay = 1) => {
  let embededUrl, platform

  // Determine video source
  const vimeoMatch = Array.isArray(videoUrl?.match(/vimeo/gi))
  const youtubeMatch = Array.isArray(videoUrl?.match(/(youtu\.*be)/gi))

  if (vimeoMatch) {
    const vimeoId = parseVimeo(videoUrl)
    embededUrl = vimeoId
      ? `https://player.vimeo.com/video/${vimeoId}?title=0&byline=0&portrait=0&autoplay=${autoplay}`
      : null
    platform = 'vimeo'
  } else if (youtubeMatch) {
    const youtubeId = parseYoutube(videoUrl)
    embededUrl = youtubeId ? `https://www.youtube.com/embed/${youtubeId}?autoplay=${autoplay}` : null
    platform = 'youtube'
  }
  //
  else embededUrl = videoUrl

  return { embededUrl, platform }
}

function parseYoutube(url) {
  var ID = ''
  url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i)
    ID = ID[0]
  } else {
    ID = url
  }
  return ID
}

function parseVimeo(url) {
  const extraCheck = ['channels', 'groups', 'album']
  url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
  )

  let matchedResult = RegExp.$6
  if (extraCheck.indexOf(matchedResult) !== -1) {
    let choppedUrl = url.split('?')[0].split('/')
    matchedResult = choppedUrl.pop() // get last item of array
  }

  return matchedResult
}

export default getEmbedUrlAndPlatform
