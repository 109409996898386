import MediaImage from '../mediaImage/MediaImage'
import MediaVideo from '../mediaVideo/MediaVideo'
import ContentMediaText from '../contentMediaText/ContentMediaText'
import ContentText from '../contentText/ContentText'
import ContentTwoImages from '../contentTwoImages/ContentTwoImages'
import MediaGallery from '../mediaGallery/MediaGallery'
import SectionItems from '../sectionItems/SectionItems'

const ProjectBuilder = ({ data, fieldPrefix = 'Project_Singleprojectfields_ContentBuilder_' }) => {
  let outputBlocksArray = data?.map((element, index) => {
    const componentName = element?.fieldGroupName?.replace(fieldPrefix, '')

    switch (componentName) {
      case 'ContentImageFull':
        return <MediaImage key={index} image={element?.image} variant={element?.variant} />

      case 'Videoembed':
        return (
          <MediaVideo key={index} videoUrl={element?.videoUrl} posterImage={element?.videoPoster} />
        )

      case 'ContentMediaText':
        return (
          <ContentMediaText
            key={index}
            headline={element.headline}
            title={element.title}
            description={element.description}
            image={element.image}
            imageCaption={element.imageCaption}
            imageAlignment={element.imageAlignment}
          />
        )

      case 'ContentText':
        return <ContentText key={index} title={element?.title} text={element?.text} />

      case 'ContentTwoImages':
        return (
          <ContentTwoImages
            key={index}
            imageLeft={element.imageLeft}
            imageRight={element.imageRight}
            captionLeft={element.captionLeft}
            captionRight={element.captionRight}
          />
        )

      case 'ImageSlider':
        return <MediaGallery key={index} items={element?.items} />

      case 'Awards':
        return (
          <SectionItems
            className={'mb-0 md:mb-150'}
            heading={'Awards'}
            key={index}
            itemsArray={element?.items}
            defaultNoOfVisibleItems='4'
            sectionCta={{
              type: 'internal',
              label: 'All Awards',
              buttonColor: 'none',
              fullWidthMobile: true,
            }}
          />
        )

      default:
        return null
    }
  })

  return outputBlocksArray?.length ? outputBlocksArray : null
}

export default ProjectBuilder
