import clsx from 'clsx'
import { gsap } from 'gsap'
import { useEffect, useState, useRef } from 'react'

import { useHeadingAnimationRaceConditionFix } from '@big-d-companies/animation'

import {
  Cta,
  Heading,
  Container,
  Paragraph,
  SubHeading,
  VideoModal,
  PlayButton,
  CustomImage,
  GridContainer,
  SectionContainer,
} from '../index'
import useIntroEnded from '../../hooks/useIntroEnded'
import { scrollToTargetAdjusted } from '../../utils'
import SplitText from '../../utils/SplitText/SplitText'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

import s from '../mediaVideo/MediaVideo.module.scss'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(SplitText)
}

const HeroCareers = ({ cta, cta2, heroImage, heroVideo, subHeading, heroHeading, description }) => {
  const { introEnded } = useIntroEnded()
  const headingRef = useRef()
  const imageRef = useRef()
  const q = gsap.utils.selector(imageRef)
  const subHeadingRef = useRef()
  const paragraphRef = useRef()
  const qh = gsap.utils.selector(paragraphRef)
  const ctaRef = useRef()
  const tl = useRef()

  const [isVideoPlayerEnabled, setIsVideoPlayerEnabled] = useState(false)
  const animateHeadingIn = useHeadingAnimationRaceConditionFix(headingRef)

  useEffect(() => {
    // Split Text
    new SplitText(paragraphRef?.current?.firstChild, {
      type: 'lines',
      linesClass: 'lines',
    })

    new SplitText(paragraphRef?.current?.firstChild, {
      type: 'lines',
      linesClass: 'overflow-y-hidden',
    })

    tl.current = gsap
      .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
      .set(imageRef?.current, { opacity: 0, yPercent: 10 })
      .set(paragraphRef?.current, { opacity: 0 })
      .set(ctaRef?.current, { opacity: 0 })
      .set(qh('.lines'), { yPercent: 100 })
  }, [])

  useEffect(() => {
    if (introEnded) {
      tl.current = gsap
        .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
        .addLabel('init')
        .call(animateHeadingIn, null, 'init')
        .to(imageRef?.current, { duration: 0.2, opacity: 1 }, 'init')
        .to(imageRef?.current, { yPercent: 0 }, 'init')
        .call(() => subHeadingRef?.current?.animateIn(), null, 'init+=.25')
        .to(paragraphRef?.current, { duration: 0.3, opacity: 1 }, 'init+=.5')
        .to(qh('.lines'), { yPercent: 0, stagger: 0.15 }, 'init+=.6')
        .to(ctaRef?.current, { opacity: 1 }, 'init+=1')
    }
  }, [introEnded])

  const hLengthMax = useLengthOfLongestWord(heroHeading)

  return (
    <SectionContainer className='hero-pt mb-65 md:mb-0'>
      <Container>
        <div className='flex justify-center mb-100 md:mb-130'>
          <Heading
            semantics='h1'
            text={heroHeading}
            className={clsx(
              'tracking-4 text-white text-6xl uppercase md:ml-50 md:text-9xl',
              hLengthMax > 10 ? 'h1-scale-down-A' : hLengthMax > 8 && 'h1-scale-down-B'
            )}
            indent='30'
            buildUpAnimation={true}
            ref={headingRef}
          />
        </div>

        <GridContainer className='md:mb-130'>
          {/* A - Left Image  */}
          <div
            className={clsx(
              'relative col-span-4 -ml-16 -mr-16 overflow-hidden md:-ml-50 md:mr-0',
              'flex items-center justify-center',
              !!heroVideo ? 'cursor-pointer' : '',
              s.play_btn
            )}
            ref={imageRef}
            onClick={() => setIsVideoPlayerEnabled(true)}>
            <CustomImage
              className='w-full h-full bg-image object-cover object-center'
              src={heroImage?.sourceUrl || ''}
              alt={heroImage?.altText}
              width={1415}
              height={2370}
              sizes='(max-width: 768px) 100vw, 50vw'
              layout='responsive'
              aspectRatio='aspect-w-1 aspect-h-1 md:aspect-w-9 md:aspect-h-14'
            />

            {!! heroVideo && <PlayButton /> }
          </div>

          {/* B - Right - Copy */}
          <div className='col-span-4 pt-70 md:col-start-5 md:pt-80'>
            <SubHeading
              text={subHeading}
              className='mb-40 md:mb-80'
              buildUpAnimation={true}
              ref={subHeadingRef}
            />

            <Paragraph
              text={description}
              overrideClassName='mb-40 text-3xl-A tracking-4 md:mb-80 md:mr-100 md:text-4xl-A text-primary font-bebasNeue'
              ref={paragraphRef}
            />

            <div ref={ctaRef} className='flex flex-col md:flex-row gap-[1rem]'>
              <Cta
                cta={{
                  label: cta.label,
                  clickHandler: () => {
                    window.location.href = cta.url
                  },
                }}
              />

              { cta2.label && cta2.url &&
                <Cta
                  cta={{
                    label: cta2?.label,
                    buttonColor: 'secondary',
                    borderClassName: 'border-primary',
                    clickHandler: () => {
                      window.location.href = cta2?.url
                    },
                  }}
                />
              }
            </div>
          </div>
        </GridContainer>

        <VideoModal
          id='heroCareersVideo'
          isModalActive={isVideoPlayerEnabled}
          onClose={() => setIsVideoPlayerEnabled(false)}
          src={heroVideo}
        />
      </Container>
    </SectionContainer>
  )
}

export default HeroCareers
