import { useRouter } from 'next/router'
import { useEffect } from 'react'
import dynamic from 'next/dynamic'

import { scrollToTargetAdjusted } from '../../utils'

const MainContainer = dynamic(() => import('../index').then(mod => mod.MainContainer))
const MediaListText = dynamic(() => import('../index').then(mod => mod.MediaListText))
const ThreeByTwoGrid = dynamic(() => import('../index').then(mod => mod.ThreeByTwoGrid))
const MediaGridImages = dynamic(() => import('../index').then(mod => mod.MediaGridImages))
const MediaSliderCards = dynamic(() => import('../index').then(mod => mod.MediaSliderCards))
const HeroFullSizeSlider = dynamic(() => import('../index').then(mod => mod.HeroFullSizeSlider))

const ResortsResidentialCommonLayout = ({ pageData, category }) => {
  const router = useRouter()
  useEffect(() => {
    if (process?.browser) {
      // detect ID in url & then scroll to
      let targetElementID = router?.asPath?.split('#')?.[1]
      scrollToTargetAdjusted(targetElementID, 60)
    }
  }, [])

  const hero = pageData?.signaturePageResortFields?.header
  const postConstruction = pageData?.signaturePageResortFields?.postConstruction
  const constManagement = pageData?.signaturePageResortFields?.constManagement
  const ourProjects = pageData?.signaturePageResortFields?.ourProjects
  const ourProjectsList = pageData?.projectsList

  const preconServices = pageData?.signaturePageResortFields?.preconServices

  const postConstructionCards = postConstruction?.cards?.map((item, index) => {
    let output = item
    item.headline = (index + 1).toString().padStart(2, '0')
    return output
  })

  return (
    <MainContainer>
      {/* SECTION A - HERO SLIDER (FULL SIZE) */}
      <HeroFullSizeSlider
        heroHeading={hero?.title}
        subHeading={hero?.tagline}
        sliderImages={hero?.images}
      />

      {/* SECTION B - Our Projects - Image Grid - 4x4 */}
      <MediaGridImages
        heading={ourProjects?.title}
        subHeading={ourProjects?.headline}
        description={ourProjects?.description}
        projects={ourProjectsList}
        category={category}
      />

      {/* SECTION D - PreCon Services  - 3x2*/}
      <MediaListText
        className='py-65 md:py-150'
        background='bg-secondary'
        image={preconServices?.image}
        heading={preconServices?.title}
        subHeading={preconServices?.headline}
        accordianItems={preconServices?.items}
      />

      {/* SECTION C - Construction Management */}
      <MediaListText
        imagePosition='right'
        className='py-20 md:py-150'
        background='bg-background'
        heading={constManagement?.title}
        subHeading={constManagement?.headline}
        image={constManagement?.image}
        accordianItems={constManagement?.items}
      />

      {/* SECTION E - Post Construction - 3x1  */}
      <MediaSliderCards
        className='scrollbar-hide pb-0 bg-background md:pt-150'
        heading={postConstruction?.title}
        subHeading={postConstruction?.headline}
        /// all team-information details is passed via this teamInfoArray to handle Modal appropriately
        modals={false}
        sliderCardsInfoArray={postConstructionCards}
      />
    </MainContainer>
  )
}

export default ResortsResidentialCommonLayout
