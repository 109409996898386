import { forwardRef } from 'react'
import clsx from 'clsx'
import Styles from './Paragraph.module.scss'
const Paragraph = forwardRef(
  (
    {
      text = '',
      className = '', // in case you want to extend the current styles
      overrideClassName = '', // in case you want to completely override the default styles
      semantics = 'div', //default
    },
    ref
  ) => {
    if (!text) return null // handle undefined or empty cases

    const defaultClassName = `text-base-A  ${className}`

    // Provide a complete override from outside
    const appliedClassName = overrideClassName ? overrideClassName : defaultClassName

    let component

    // switch the wrapper (p or div) based on semantics props
    switch (semantics) {
      case 'p':
        component = (
          <p className={appliedClassName} ref={ref}>
            {text}
          </p>
        )
        break

      case 'div':
        component = (
          <div
            className={clsx(appliedClassName, Styles.paragraph)}
            dangerouslySetInnerHTML={{ __html: text }}
            ref={ref}></div>
        )
        break
    }

    return component
  }
)

export default Paragraph
