import clsx from 'clsx'

const SectionHeading = ({ position = 'left', children, className = '' }) => {
  // Position left & centre
  return (
    <div
      className={clsx(
        position === 'left' && 'col-span-full md:col-start-0.5',
        position === 'center' && 'col-span-full text-center',
        className
      )}>
      {children}
    </div>
  )
}

export default SectionHeading
