import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import Image from 'next/legacy/image'
import { IMG_LAZY_LOAD_THRESHOLD } from '../../../utils/siteConfig'
import Styles from './GridItem.module.scss'
import { animated, useSpring } from '@react-spring/web'

import { Heading, SubHeading, CustomImage, LinkWrapper } from '../../index'

// Individual Project Component
const GridItem = ({ item, index, tall, style, isViewportMobile, outAnimation, isSmallTestimonial = false, visibleNth = [] }) => {
  const [showReadMore, setShowReadMore] = useState(false)
  const lineClampRef = useRef(null)

  const styles = useSpring({
    transform: outAnimation ? 'translate3d(0%,0,0)' : 'translate3d(-100%,0,0)',
    immediate: outAnimation ? false : true,
  })

  let imageSrc, imageAlt
  const stateShortname =
  item?.SingleProjectFields?.location ? item?.SingleProjectFields?.location[0]?.locationsFields?.location?.stateShortname : null

  if (item?.SingleProjectFields?.headerListingImage) {
    imageSrc = item?.SingleProjectFields?.headerListingImage?.sourceUrl
    imageAlt = item?.SingleProjectFields?.headerListingImage?.altText
  } else {
    imageSrc = item?.SingleProjectFields?.headerImage?.sourceUrl
    imageAlt = item?.SingleProjectFields?.headerImage?.altText
  }

  const testimonial = item?.testimonial || null

  useEffect(() => {
    if (lineClampRef.current) {
      setShowReadMore(lineClampRef.current.scrollHeight > lineClampRef.current.clientHeight)
    }
  }, [lineClampRef])

  const handleClick = e => {
    document.querySelectorAll('#projects-grid .ProjectGridsWith').forEach(item => item.classList.add('opacity-10'))
    e.target.closest('.ProjectGridsWith').classList.remove('opacity-10')

    e.target.closest('.ProjectGridsWith').querySelector('.FullTestimonial').style.display = 'flex'
  }

  return (
    <LinkWrapper
      style={style}
      className={clsx('relative h-full overflow-hidden LinkWrapper', Styles.LinkWrapper)}
      target={null} // internal
      href={item?.uri}>      
      <div className={clsx(`absolute left-0 top-0 w-full h-full ${testimonial === null || !visibleNth.includes(index) ? 'bg-primary' : 'border border-primary'}`)}></div>
      <animated.div
        className={clsx('w-full h-full')}
        style={{ transform: style.scale, willChange: 'transform', opacity: (testimonial === null || !visibleNth.includes(index) ? 1 : 0) }}>          
          { isSmallTestimonial ? 
            <div className='w-full h-auto aspect-w-1 aspect-h-1'></div>
          :
            tall ? (
              <Image
                // Native image must be used to get that accurate (1:2.something) aspect ratio
                layout='fill'
                src={imageSrc}
                alt={imageAlt}
                className='w-full h-auto object-cover object-center'
                lazyBoundary={IMG_LAZY_LOAD_THRESHOLD}
              />
            ) : (
              <CustomImage
                className='w-auto h-auto object-cover'
                src={imageSrc}
                alt={imageSrc}
                aspectRatio='aspect-w-1 aspect-h-1'
              />
            )
          }
      </animated.div>

      {/* Grid Item Info (index & Name) */}
      <div
        className={clsx(
          'absolute z-0 left-0 top-0 flex flex-col items-center justify-center w-full h-full',
          testimonial === null || !visibleNth.includes(index) ? 'md:hover:opacity-100 md:opacity-0' : '', // desktop view
          'opacity-100', // mobile view
          'transition-opacity duration-300 ease-in-out',
          'px-10 text-center md:px-20',          
        )}>
          { testimonial === null ? <>
            <SubHeading
              text={stateShortname || 'none'}
              // text={index < 10 ? `0${index}` : index}
              // text='CA'
              // text={'none'}
              className='z-20 mb-35 text-secondary'
              classNameDash={Styles.Dash}
            />
            <Heading
              semantics='h3'
              text={item?.title}
              className='tracking-4 z-10 text-secondary text-base-B uppercase md:text-3xl-E'
            />
          </> : 
          <>
            <q className={clsx(Styles.quote)}>
              <span ref={lineClampRef} className={Styles.lineClamp}>{testimonial?.quote}</span>
            </q>
            <p className={Styles.name}>{testimonial?.name}</p>
            { showReadMore &&
              <button 
                className={clsx(
                  'text-xs-A md:text-sm-A uppercase cursor-pointer',
                  'absolute bottom-5 md:bottom-15 left-5 md:left-15 z-10 hover:underline',
                  'focus-visible:outline-black focus:outline-none',
                  !visibleNth.includes(index) ? 'text-white md:text-secondary' : 'text-primary'
                )}
                onClick={(e) => handleClick(e)}
              >Read More</button>
            }
          </> }
        {/* Background Tint */}
        <div className={`absolute z-0 w-full h-full opacity-40 md:opacity-90 ${testimonial === null || !visibleNth.includes(index) ? 'bg-primary' : 'border border-primary'}`}></div>
      </div>

      <animated.div
        style={{ transform: style.transform }}
        className={clsx('absolute left-0 top-0 w-full h-full bg-background')}
      />
      <animated.div
        style={{ transform: styles.transform }}
        className={clsx('absolute left-0 top-0 w-full h-full bg-background')}
      />
    </LinkWrapper>
  )
}

export default GridItem
