/* eslint-disable @next/next/next-script-for-ga */
import Head from 'next/head'
const Analytics = () => {
  const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS
  const GOOGLE_SITE_VERIFICATION = process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION

  return (
    <Head>
      {GA_MEASUREMENT_ID && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${GA_MEASUREMENT_ID}');
            `
          }}
        />
      )}

      {GOOGLE_SITE_VERIFICATION && (
        <meta
          name="google-site-verification"
          content={`${GOOGLE_SITE_VERIFICATION}`}
        />
      )}
    </Head>
  )
}

export default Analytics
