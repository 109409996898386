import clsx from 'clsx'
import { useRef } from 'react'
import Styles from './ThreeDMap.module.scss'
import { CustomImage } from '../index'

/**
 * 3D Map of USA
 * Shared across About & Contacts Page
 */
const ThreeDMap = () => {
  const imageContainer = useRef()
  const THRESHOLD = 15

  function handleMouseMove(e) {
    const { clientX, clientY, target } = e
    const { clientWidth, clientHeight, offsetLeft, offsetTop } = target
    const horizontal = (clientX - offsetLeft) / clientWidth
    const vertical = (clientY - offsetTop) / clientHeight
    const rotateX = (THRESHOLD / 2 - horizontal * THRESHOLD).toFixed(2)
    const rotateY = (vertical * THRESHOLD - THRESHOLD / 2).toFixed(2)

    imageContainer.current.style.transform = `perspective(${clientWidth}px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) scale3d(1, 1, 1)`
  }

  function handleMouseLeave(e) {
    imageContainer.current.style.transform = `perspective(${e.currentTarget.clientWidth}px) rotateX(0deg) rotateY(0deg)`
  }

  return (
    <div
      ref={imageContainer}
      className={clsx(Styles.imageContainer)}
      onMouseMove={ev => handleMouseMove(ev)}
      onMouseLeave={ev => handleMouseLeave(ev)}>
      <CustomImage
        src='/images/map-usa.jpg' // TODO: PASS MAP FROM WP
        alt='Map of USA'
        data-preload={false}
        aspectRatio='aspect-w-10 aspect-h-7'
        className='w-full h-full object-contain'
      />
      <div className={clsx(Styles.imageRef, 'absolute bottom-0 left-0 right-0 top-0')}>
        <CustomImage
          src='/images/map-usa-ref.png' // TODO: PASS MAP FROM WP
          alt='Map of USA'
          data-preload={false}
          aspectRatio='aspect-w-10 aspect-h-7'
          className='w-full h-full object-contain'
        />
      </div>
    </div>
  )
}

export default ThreeDMap
