import { SectionContainer, Container, GridContainer, ImageSlider } from '../index'

const MediaGallery = ({ items = [] }) => {
  const sliderImages = items?.map(item => {
    return {
      image: {
        sourceUrl: item?.image?.sourceUrl,
        altText: item?.image?.altText,
        caption: item?.caption,
      },
    }
  })

  return (
    <SectionContainer className='mb-80 md:mb-150'>
      <Container>
        <GridContainer>
          <div className='col-span-4 mx-16 md:col-span-7 md:col-start-1 md:-ml-60 md:-mr-40'>
            <ImageSlider
              sliderImages={sliderImages}
              className='w-full md:w-11/12'
              captionClassName='md:text-sm-A'
              imageAspectRatio='aspect-h-10 aspect-w-16 md:aspect-h-7 md:h-full'
              gutterPct={0}
              gutter={0}
              fromScale={0.9}
              controllerMobBottomCenter={true}
              clipPath='left'
            />
          </div>
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default MediaGallery
