import clsx from 'clsx'
import Styles from './HeroApply.module.scss'
import { useRef, useEffect, useContext } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import {
  Cta,
  Heading,
  Paragraph,
  Container,
  SubHeading,
  CustomImage,
  GridContainer,
  SectionContainer,
} from '../index'
import useIntroEnded from '../../hooks/useIntroEnded'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const HeroApply = ({
  heroHeading,
  heroSubtitle,
  heroImage,
  heroImageAlt,
  heroImageCaption,
  subHeading,
  description,
  ctaA,
  ctaB,
}) => {
  const containerRef = useRef()
  const subHeadingRef = useRef()
  const headingRef = useRef()
  const figureRef = useRef()
  const tlH = useRef()
  const tl = useRef()
  const q = gsap.utils.selector(containerRef)

  const { introEnded } = useIntroEnded()

  useEffect(() => {
    tlH.current = gsap
      .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
      .set(q('.paragraph'), { yPercent: 105 })
      .set(q('.cta'), { yPercent: 100, opacity: 0 })

    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 768px)': function () {
        tl.current = gsap
          .timeline({
            scrollTrigger: {
              trigger: figureRef?.current,
              start: '0 100%',
              end: '100% 100%',
              scrub: true,
              invalidateOnRefresh: true,
            },
          })
          .addLabel('init')
          .fromTo(
            figureRef?.current,
            {
              y: 0,
            },
            {
              y: 200,
            },
            'init'
          )

        return function () {
          tl?.current?.kill()
          gsap.set(figureRef?.current, { clearProps: true })
        }
      },
    })
  }, [])

  useEffect(() => {
    if (introEnded) {
      tlH.current = gsap
        .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
        .addLabel('init')
        .call(() => subHeadingRef?.current?.animateIn(), null, 'init')
        .call(() => headingRef?.current?.animateIn(), null, 'init+=.3')
        .to(q('.paragraph'), { yPercent: 0 }, 'init+=1.4')
        .to(q('.cta'), { yPercent: 0, opacity: 1, stagger: 0.15 }, 'init+=1.8')
    }
  }, [introEnded])

  const hLengthMax = useLengthOfLongestWord(heroHeading)

  return (
    <SectionContainer className='hero-pt pb-0 md:pb-40'>
      <Container>
        <GridContainer className='pb-60 md:pb-100 md:border-b md:border-primary-light'>
          <div className='col-span-4 md:col-span-7 md:col-start-1' ref={containerRef}>
            <SubHeading
              text={subHeading}
              className='mb-40 md:mb-60'
              ref={subHeadingRef}
              buildUpAnimation={true}
            />

            <Heading
              semantics='h1'
              text={heroHeading}
              className={clsx(
                'tracking-4 mb-20 text-white text-3xl-B uppercase md:text-6xl-B',
                hLengthMax > 10 ? 'h1-scale-down-A' : hLengthMax > 8 && 'h1-scale-down-B'
              )}
              buildUpAnimation={true}
              ref={headingRef}
            />

            <div className='mb-35 overflow-hidden md:mb-60'>
              <Paragraph text={heroSubtitle} className='paragraph' />
            </div>

            <div className='flex flex-col space-y-20 md:flex-row md:space-x-40 md:space-y-0'>
              <Cta cta={ctaA} className={'cta'} />
              <Cta cta={ctaB} className={'cta'} />
            </div>
          </div>
        </GridContainer>

        <GridContainer className='mt-0 md:mt-100'>
          {/* A - Left Image  */}
          <div ref={figureRef} className='col-span-4 mb-40 pr-15 md:col-span-2 md:mb-60 md:pr-0'>
            <CustomImage
              src={heroImage}
              alt={heroImageAlt}
              caption={heroImageCaption}
              aspectRatio='aspect-w-11 aspect-h-16'
              className='w-full h-auto object-cover object-center'
              imageClassName='bg-image'
              sizes='(max-width: 768px) 100vw, 50vw'
            />
          </div>

          {/* B - Right - Copy i.e. Role Description */}
          <div className='col-span-4 md:col-span-6 md:col-start-3'>
            <div>
              <Paragraph
                text={description}
                className={clsx(
                  'pb-40 text-primary-light md:ml-80 md:mr-220 md:pb-70',
                  Styles.textArea
                )}
              />
            </div>
          </div>
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default HeroApply
