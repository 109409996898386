import { useGlobalStore } from '../store'

import bindActions from '../store/bindActions'
import menusReducer from '../store/reducers/menus'

// Custom Hook to expose all the props and binded actions
const useMenus = () => {
  const { state, dispatch } = useGlobalStore()

  // List of Props
  const menus = state?.menus

  // List of Actions
  const setMenus = menusReducer?.actions?.setMenus

  // Bind all Actions to globalDispatch (important)
  const boundActions = bindActions({ setMenus }, dispatch)

  // expose
  return { menus, ...boundActions }
}

export default useMenus
