/**
 * Add Site Configuration Params here
 */
export const SITENAME = 'dconstruction'
export const PROJECT_PARENT_CATEGORY_ID = 160 // PROJECTS parent category
export const WEBSITE_PARENT_CATEGORY_ID = 157 // NEWS parent category
export const GLOBAL_SITENAME = 'bigd'

// Breakpoints
export const BREAKPOINTS = {
  XXXS: 0,
  XXS: 320,
  XS: 415,
  S: 650,
  M: 768,
  L: 1024,
  XL: 1440,
  XXL: 1680,
}

/**
 * Careers
 * BIRDDOGH API filter
 */
export const CAREERS_FILTER = ['Park City', 'Jackson', 'Bozeman', 'Idaho Falls']

// Slug Names of Menus created on the frontend
export const MENU_SLUGS = {
  header: {
    primary: 'dovetail-primary-nav',
    secondary: 'dovetail-secondary-nav',
  },
  footer: {
    leftCol: 'dovetail-footer-left-column',
    centralCol: 'dovetail-footer-central-column',
    rightCol: 'dovetail-footer-right-column',
    bottom: 'dovetail-footer-bottom',
  },
  socialNetworks: 'dovetail-social-networks',
}

export const STATIC_PAGE_WP_IDS = {
  home: 5519,
  about: 5511,
  contact: 5517,
  careers: 5514,
  careersDetail: 5515,
  news: 5516,
  warranty: 5529,
  imprint: 5525,
  resort: 5527,
  residential: 5526,
  media: 5523,
  magazines: 5522,
  termsOfServices: 5528,
  dataProtection: 5518,
  givingBack: 9199,
  projects: 9503,
}

export const SLUGS = {
  news: 'news',
  careers: 'careers',
}

// Used by News & Community overview pages
export const CATEGORY_NEWS_SLUGS = {
  news: 'news-dovetail',
  community: 'community-dovetail',
  givingBack: 'giving-back'
}

// Form Configuration
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
export const FILE_SIZE_LIMIT_MB = 8 // MegaBytes

// News Page Configuration
export const PAGINATION_SIZE = 7
export const RECOMMENDATION_POSTS_COUNT = 4
export const MAX_PAGES_MOBILE = 4

export const IMG_LAZY_LOAD_THRESHOLD = '200%'
