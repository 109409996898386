import clsx from 'clsx'
import React, { useEffect, useRef } from 'react'
import Image from 'next/legacy/image'
import { IMG_LAZY_LOAD_THRESHOLD } from '../../utils/siteConfig'
import { Heading, SubHeading, Cta, Paragraph } from '../index'
import { isObjectEmpty } from '../../utils'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

const CopyGroup = ({
  cta = {},
  ctaBotton = false,
  index,
  heading = '',
  description = '',
  subHeading = '',
  subHeadingclassName = '',
  className = '',
  variationDash,
  onScrollAnimation = false,
  onScrollAnimationTrigger = false,
  // additional params for customization
  paragraphClassName = '', // additional classnames from outside,
  isGivingBack = false,
}) => {
  const CopyGroupRef = useRef()

  if (onScrollAnimation || onScrollAnimationTrigger) {
    const q = gsap.utils.selector(CopyGroupRef)
    const tl = useRef()

    useEffect(() => {
      gsap.set(q('.paragraph'), {
        yPercent: 100,
      })

      tl.current = gsap
        .timeline({
          defaults: { duration: 1, ease: 'bigd-ease-out', overwrite: 'auto' },
          paused: true,
        })
        .to(
          q('.paragraph'),
          {
            yPercent: 0,
            stagger: 0.15,
          },
          onScrollAnimationTrigger ? `+${index / 10 + 0.5}` : null
        )

      ScrollTrigger.create({
        trigger: onScrollAnimationTrigger ? onScrollAnimationTrigger.current : q('.paragraph'),
        start: '0 70%',
        onEnter: () => {
          tl?.current?.play()
        },
      })
    }, [])
  }

  return (
    <div ref={CopyGroupRef} className={className}>
      {typeof subHeading === 'string' ? (
        <SubHeading
          text={index ? `0${index}` : subHeading}
          className='mb-20 text-white md:mb-35'
          variationDash={variationDash}
          subHeadingclassName={subHeadingclassName}
          onScrollAnimation={onScrollAnimation}
          onScrollAnimationTrigger={onScrollAnimationTrigger}
          onScrollAnimationDelay={index ? index / 10 : 0}
        />
      ) : (
        <div className='mb-40 w-full h-full'>
          {subHeading?.sourceUrl ? (
            <Image
              height='50'
              width='400' // max-width
              src={subHeading?.sourceUrl}
              alt={subHeading?.altText}
              className='object-contain object-left'
              lazyBoundary={IMG_LAZY_LOAD_THRESHOLD}
            />
          ) : null}
        </div>
      )}

      <Heading
        semantics='h3'
        text={heading}
        className={clsx(
          'tracking-4 text-2xl-F uppercase md:text-3xl-E',
          description && 'mb-28 md:mb-40'
        )}
      />

      <div
        className={clsx(
          'overflow-hidden',
          !isObjectEmpty(cta) && (ctaBotton ? (isGivingBack ? 'mb-15 md:mb-30' : '') : 'mb-30 md:mb-60')
        )}>
        <Paragraph
          text={description}
          overrideClassName={clsx(
            'paragraph text-primary-light text-base-A md:text-base',
            paragraphClassName
          )}
        />
      </div>

      {cta && <Cta cta={cta} className={clsx(ctaBotton && 'md:absolute bottom-0')} />}
    </div>
  )
}

export default CopyGroup
