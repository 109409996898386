import clsx from 'clsx'
import formatDate from '../../utils/formatDate'
import Paragraph from '../paragraph/Paragraph'
import { SLUGS } from '../../utils/siteConfig'
import { Heading, Container, SubHeading, SectionContainer, NewsRecommendationCard } from '../index'

const NewsRecommendation = ({ news = [], newsCategory }) => {
  if (!news) return null

  const isCommunity = newsCategory === 'community'

  const staticCopies = {
    subHeading: isCommunity ? 'More' : "We've Got it under control",
    heading: isCommunity ? 'COMMUNITY<br/> FOUNDATIONS' : 'Other<br/> Updates',
  }

  return (
    <SectionContainer className={clsx('bg-background md:pb-180 md:pt-150', 'py-80')}>
      <Container>
        {/* <div className='pb-60 border-b border-primary-light md:pb-130 md:pl-80'>
          <SubHeading text={staticCopies?.subHeading} className='mb-40 uppercase md:mb-35' />

          <Heading
            semantics='h2'
            text={staticCopies?.heading}
            className='tracking-4 text-4xl-D uppercase md:text-8xl'
            indent='13'
          />
        </div> */}

        {news ? (
          news?.map((news, index) => (
            <NewsRecommendationCard
              key={index}
              subHeading={isCommunity ? ' ' : formatDate(news?.date)}
              post={news}
              href={`/${SLUGS.news}/${news.slug}`}
            />
          ))
        ) : (
          <Paragraph
            text='News Section is currently empty. Please check later.'
            className='py-20'
          />
        )}
      </Container>
    </SectionContainer>
  )
}

export default NewsRecommendation
