import clsx from 'clsx'
import Image from 'next/legacy/image'
import { IMG_LAZY_LOAD_THRESHOLD } from '../../utils/siteConfig'
import { gsap } from 'gsap'
import React, { useState, useEffect, useRef } from 'react'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import {
  Modal,
  Heading,
  HeroText,
  ThreeDMap,
  Container,
  Paragraph,
  GridContainer,
  SectionContainer,
} from '../index'
import Styles from './ThreeColContact.module.scss'
import useIntroEnded from '../../hooks/useIntroEnded'

// Individual Card Components (LOCAL)
const ContactCards = ({ className, index, modalIndex, contact, modalItems }) => {
  const tl = useRef()
  const containerRef = useRef()
  const q = gsap.utils.selector(containerRef)

  useEffect(() => {
    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 768px)': function () {
        tl.current = gsap.set(q('.contactCard'), { yPercent: 20, opacity: 0 })
        tl.current = gsap
          .timeline({
            defaults: { duration: 1, ease: 'bigd-ease-out' },
            paused: true,
          })
          .addLabel('init')
          .to(q('.contactCard'), { opacity: 1, duration: 0.25 }, `init+=${modalIndex * '.15'}}`)
          .to(q('.contactCard'), { yPercent: 0 }, `init+=${modalIndex * '.15'}}`)

        ScrollTrigger.create({
          trigger: containerRef?.current,
          start: '0 90%',
          onEnter: () => {
            tl?.current?.play()
          },
        })
      },
    })
  }, [])

  const name = contact?.title
  const postalAddress = contact?.description
  const contactPersonImage = contact?.contact?.image
  const contactPersonName = contact?.contact?.name
  const contactPersonTitle = contact?.contact?.position
  const contactPersonOffice = contact?.contact?.office
  const contactPersonCell = contact?.contact?.phone
  const contactDirections = contact?.link?.externalUrl
  const contactDirectionsLabel = contact?.link?.label

  const [isModalActive, setModalActive] = useState(false)
  const [activeModalInfo, setActiveModalInfo] = useState({})

  const showModal = event => {
    const { name } = event?.target
    const bio = modalItems[name]

    setActiveModalInfo(bio)
    setModalActive(true)
  }

  const closeModal = event => {
    event.stopPropagation()

    setModalActive(false)
    setActiveModalInfo({})
  }

  return (
    <div key={index} ref={containerRef} className={`overflow-hidden py-40 md:py-0 ${className}`}>
      <div className={'contactCard'}>
        {/* <div className='border border-primary rounded-full w-30 h-30 flex justify-center items-center uppercase md:mb-25'>{index}</div> */}

        {/* Don't apply scale-down here. Max size is 4xl-B */}
        <Heading
          semantics='h2'
          text={name}
          className='tracking-4 mb-20 text-primary text-2xl-F uppercase md:mb-32 md:text-4xl-B'
        />

        <div className='flex items-start mt-30 space-x-20'>
          <figure className={clsx('w-1/2 leading-3', Styles.contact_profile)}>
            <Image
              className='w-full h-auto object-cover'
              src={contactPersonImage?.sourceUrl}
              alt={contactPersonImage?.altText}
              width={184 * 2}
              height={250 * 2}
              objectFit='cover'
              sizes='(max-width: 768px) 100vw, 50vw'
              lazyBoundary={IMG_LAZY_LOAD_THRESHOLD}
            />
            {/* <figcaption></figcaption> */}
          </figure>

          <div className='flex flex-col text-xs-B -tracking-3 md:text-base'>
            <div>{contactPersonName}</div>
            <div>{contactPersonTitle}</div>
            <div>{contactPersonOffice}</div>
            <div>
              Cell: <a href={`tel:+1${contactPersonCell}`}>{contactPersonCell}</a>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isModalActive={isModalActive}
        closeModal={closeModal}
        backgroundColor='bg-secondary'
        image={activeModalInfo?.image}
        heading={activeModalInfo?.title}
        subHeading={activeModalInfo?.headline}
        description={activeModalInfo?.description}
        socialLinks={activeModalInfo?.socialLinks}
      />
    </div>
  )
}

const ThreeColContact = ({
  heroHeading,
  indexA,
  contactA,
  //
  indexB,
  contactB,
  //
  indexC,
  contactC,

  modalItems,
}) => {
  const tl = useRef()
  const containerRef = useRef()
  const { introEnded } = useIntroEnded()

  useEffect(() => {
    tl.current = gsap.set(containerRef?.current, { opacity: 0, yPercent: 10 })
  }, [])

  useEffect(() => {
    if (introEnded) {
      tl.current = gsap
        .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
        .addLabel('init')
        .to(containerRef?.current, { opacity: 1, duration: 0.25 }, 'init+=.8')
        .to(containerRef?.current, { yPercent: 0 }, 'init+=.8')
    }
  }, [introEnded])

  return (
    <SectionContainer className='hero-pt md:mb-170'>
      <Container>
        <div className='flex justify-center mb-20 md:mb-130'>
          <HeroText
            text={heroHeading}
            className='tracking-4 text-white text-6xl uppercase md:text-9xl'
          />
        </div>

        <GridContainer className='gap-y-0'>
          { contactA ?
            <ContactCards
              className='col-span-4 text-white md:col-span-3.5 md:col-start-1'
              index={indexA}
              modalIndex={0}
              contact={contactA}
              modalItems={modalItems}
            /> : <></>
          }
          { contactB ?
            <ContactCards
              className={clsx(
                'col-span-4 items-start justify-start text-white md:col-span-4 md:pl-80', //  md:col-start-3
                // 'md:mx-10 md:pr-80 md:px-100',
                // 'md:mx-10',
                'border-b border-t border-primary-light md:border-b-0 md:border-l md:border-t-0'
              )}
              index={indexB}
              modalIndex={1}
              contact={contactB}
              modalItems={modalItems}
            /> : <></> 
          }
          { contactC ? 
            <ContactCards
              className='col-span-4 pb-80 text-white md:col-span-3 md:col-start-6 md:pl-70'
              index={indexC}
              modalIndex={2}
              contact={contactC}
              modalItems={modalItems}
            /> : <></>
          }
        </GridContainer>

        {/* <GridContainer className='gap-y-0'>
          { contactA ?
            <ContactCards
              className='col-span-4 text-white md:col-span-2 md:col-start-1'
              index={indexA}
              modalIndex={0}
              contact={contactA}
              modalItems={modalItems}
            /> : <></>
          }
          { contactB ?
            <ContactCards
              className={clsx(
                'col-span-4 items-start justify-start text-white md:col-span-3 md:col-start-3',
                'md:mx-10 md:pr-80 md:px-100',
                'border-b border-t border-primary-light md:border-b-0 md:border-l md:border-r md:border-t-0'
              )}
              index={indexB}
              modalIndex={1}
              contact={contactB}
              modalItems={modalItems}
            /> : <></> 
          }
          { contactC ? 
            <ContactCards
              className='col-span-4 pb-80 text-white md:col-span-3 md:col-start-6 md:pl-70'
              index={indexC}
              modalIndex={2}
              contact={contactC}
              modalItems={modalItems}
            /> : <></>
          }
        </GridContainer> */}
      </Container>
    </SectionContainer>
  )
}

export default ThreeColContact
